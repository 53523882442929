import progressStorage from 'core/progressStorage';
import { getValueFromUrl } from 'utils/window';
import { STATUS_OK } from 'constants/progressStorage';
import { getUser, shouldSaveCrossDevice } from 'store/user/selectors';
import { isPublishMode } from 'store/settings/selectors';
import * as courseActions from 'store/course/actions';
import eventEmitter, { events } from 'core/events/eventEmitter';

import { ActionTypes } from './types';
import { ThunkResult } from '../types';

export const authenticate = (payload: any): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({
    type: ActionTypes.USER_AUTHENTICATED,
    payload
  });
};

export const load = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  let user = getUserFromUrl();

  if (shouldSaveCrossDevice(getState()) && isPublishMode(getState())) {
    user = (await progressStorage.identifyUser()) || user;
  }

  if (user) {
    dispatch(authenticate(user));
    await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));
  }
};

export const authorizeUser = (signInData: any): ThunkResult<Promise<string>> => async (
  dispatch,
  getState
) => {
  let status = STATUS_OK;
  if (shouldSaveCrossDevice(getState())) {
    status = await progressStorage.authorizeUser(signInData);
    if (status === STATUS_OK) {
      const user = await progressStorage.identifyUser();
      if (user) {
        signInData.name = user.name;
        await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));
      }
    }
  }

  if (status === STATUS_OK) {
    dispatch(
      authenticate({
        email: signInData.email,
        name: signInData.name,
        shortTermAccess: signInData.shortTermAccess
      })
    );
  }

  return status;
};

export const registerUser = (signUpData: any): ThunkResult<Promise<any>> => async (
  dispatch,
  getState
) => {
  let status = await progressStorage.registerUser(signUpData);
  if (status === STATUS_OK) {
    dispatch(
      authenticate({
        email: signUpData.email,
        name: signUpData.name,
        shortTermAccess: true
      })
    );

    await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));
  }

  return status;
};

export const skipAuthentication = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({ type: ActionTypes.USER_AUTHENTICATION_SKIPPED });
};

export const notSkipAuthentication = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({ type: ActionTypes.USER_AUTHENTICATION_NOT_SKIPPED });
};

export const logout = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await eventEmitter.emit(events.USER_LOGOUT, { state: getState() });
  dispatch({ type: ActionTypes.USER_UNAUTHENTICATED });
  await dispatch(courseActions.cleanup(true));
};

export const sendSecretLink = async () => {
  progressStorage.sendSecretLink();
};

function getUserFromUrl() {
  const email = getValueFromUrl('email');
  const name = getValueFromUrl('name');
  if (!email || !name) {
    return null;
  }

  return {
    name,
    email: email.toLowerCase()
  };
}
