import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'components/common/Popup';
import { getPopupVisibility } from 'store/popup/selectors';
import {
  getBackground,
  isScormMode,
  isResource,
  getSelectedLanguage
} from 'store/settings/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { isReviewEnabled } from 'store/modules/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import { isLoaderVisibility } from 'store/loader/selectors';
import Reviewable from 'components/review/Reviewable';
import { HINT_FOR_GENERAL_COMMENT } from 'constants/review';
import { HYPHENATED_LANGUAGES } from 'constants/language';
import { RootAppState } from 'store/types';
import UserMenu from '../../userMenu';
import {
  LayoutContainer,
  BackgroundContainer,
  PageContainer,
  PageContentContainer,
  PageContentChild,
  PageSidebarContainer,
  PageSidebarChild,
  BasicStylesSidebar,
  AccessibilityToast,
  AccessibilityInvertedIcon,
  ToastCloseIcon
} from './Layout.styled';
import { Slide, toast } from 'react-toastify';
import { localize } from 'core/localization';
import { withTheme } from 'styled-components';
import { Icon } from 'components/common';

type PageSidebarProps = {
  children: any;
  className?: string;
  props?: any;
};
export const PageSidebar = ({ children, className, ...props }: PageSidebarProps) => (
  <PageSidebarContainer className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </PageSidebarContainer>
);

type BasicStyledSidebarProps = {
  children?: any;
  className?: string;
  background?: any;
};
export const BasicStyledSidebar = ({
  children,
  className = '',
  ...props
}: BasicStyledSidebarProps) => (
  <BasicStylesSidebar className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </BasicStylesSidebar>
);

type PageContentProps = {
  children: any;
  className?: string;
  props?: any;
};
export const PageContent = ({ children, ...props }: PageContentProps) => (
  <PageContentContainer {...props}>
    <PageContentChild>{children}</PageContentChild>
  </PageContentContainer>
);

type LayoutProps = {
  children: any;
  background: { [key: string]: any };
  isUserAuthenticated: boolean;
  popupExpanded: boolean;
  isAccessLimited: boolean;
  isLoaderVisible?: boolean;
  isScorm: boolean;
  reviewMode: boolean;
  isResourceType: boolean;
  className?: string;
  hasUnhandledError?: boolean;
  selectedLanguage?: string;
  theme: any;
};

export class Layout extends Component<LayoutProps, {}> {
  // Make a generic toast component to reuse across AIO
  showAccessibilityToast = () => {
    const { theme } = this.props;
    toast.success(localize(`[accessibility mode enabled]`), {
      toastId: localize(`[accessibility mode enabled]`),
      pauseOnFocusLoss: false,
      theme: 'light',
      icon: (
        <>
          <AccessibilityInvertedIcon>
            <Icon
              theme={theme}
              name={`universal-access-person`}
              size={14}
              color={`contentBodyColor`}
            />
          </AccessibilityInvertedIcon>
        </>
      ),
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_RIGHT,
      transition: Slide,
      role: 'alert'
    });
  };

  accessibilityCloseButton = () => (
    <ToastCloseIcon theme={this.props.theme} name={'close'} size={6} color={'textColor'} />
  );

  render() {
    const {
      background,
      children,
      popupExpanded,
      className,
      hasUnhandledError,
      isLoaderVisible,
      isResourceType,
      theme
    } = this.props;
    const bgHeader = background.header;
    return (
      <>
        <LayoutContainer className={className}>
          <Reviewable isGeneral={true} hintName={HINT_FOR_GENERAL_COMMENT}>
            {!isLoaderVisible && !isResourceType && (
              <BackgroundContainer background={bgHeader} aria-hidden={popupExpanded} />
            )}
            <PageContainer aria-hidden={popupExpanded}>{children}</PageContainer>
            {this.shouldRenderUserMenu() && !isLoaderVisible && (
              <UserMenu
                ariaHidden={popupExpanded}
                hasUnhandledError={hasUnhandledError || false}
                showAccessibilityToast={this.showAccessibilityToast}
              />
            )}
            {popupExpanded && <Popup />}
          </Reviewable>
        </LayoutContainer>
        <AccessibilityToast theme={theme} closeButton={this.accessibilityCloseButton} />
      </>
    );
  }

  shouldEnableHyphenation = () => {
    const { selectedLanguage = 'en' } = this.props;
    return HYPHENATED_LANGUAGES.includes(selectedLanguage);
  };

  shouldRenderUserMenu() {
    const { isAccessLimited, isScorm, reviewMode, isResourceType } = this.props;

    return (!isAccessLimited && !isResourceType && !reviewMode) || isScorm;
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    background: getBackground(state),
    popupExpanded: getPopupVisibility(state),
    isUserAuthenticated: isAuthenticated(state),
    isAccessLimited: isCourseAccessLimited(state),
    isLoaderVisible: isLoaderVisibility(state),
    isScorm: isScormMode(state),
    reviewMode: isReviewEnabled(state),
    isResourceType: isResource(state),
    selectedLanguage: getSelectedLanguage(state)
  };
}

export default withTheme(connect(mapStateToProps)(Layout));
