import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import throttle from 'lodash.throttle';
import { HYPHENATED_LANGUAGES } from 'constants/language';
import {
  getColors,
  getFonts,
  getBackground,
  isResource,
  getFontSizesList,
  getSelectedLanguage
} from 'store/settings/selectors';
import SomethingWentWrong from 'pages/errors/somethingWentWrong';
import { withRouter } from 'react-router-dom';
import * as actions from 'store/app/actions';
import {
  isAppLoading,
  isAppLoadingFailed,
  isOffline,
  isLrsMisconfigurationError
} from 'store/app/selectors';
import withScrollOnRedirect from 'components/hocs/withScrollOnRedirect';
import { getTreeOfContentVisibility } from 'store/treeOfContent/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import withNavigation from 'components/hocs/withNavigation';
import { RootAppState } from 'store/types';
import CommonError from 'pages/errors/Common';
import { CircleLoader } from '../common';
import { localize } from 'core/localization';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { getThemeUtils, FontSizeOptions } from 'utils/theme';
import { getAccessibilitySettings } from 'store/accessibility/selectors';
import { AccessibilityState } from 'store/accessibility/types';
import { FONT_SIZES_LIST, PAGE_NAME } from 'constants/components';

const Course = React.lazy(() => import('../../pages/course/Course'));
const Resource = React.lazy(() => import('../../pages/resource/Resource'));

type ShellProps = {
  colors: { [key: string]: any };
  fonts: { [key: string]: any };
  background: { [key: string]: any };
  actions: { [key: string]: any };
  isLoading: boolean;
  isLoadingFailed: boolean;
  isOffline: boolean;
  isLrsMisconfigurationError: boolean;
  isLowResolution: boolean;
  isTocExpanded: boolean;
  isUserAuthenticated: boolean;
  location: { [key: string]: any };
  userAccessIsLimited: boolean;
  navigateToUrl(url: string): void;
  resources: { [key: string]: any };
  isResource: { [key: string]: any };
  courseLaunched: boolean;
  popupActions: { [key: string]: any };
  isCourseProgressRestoreFailed: boolean;
  navigateToIndex(): void;
  fontSizesList?: number[];
  theme?: any;
  accessibilitySettings: AccessibilityState;
  selectedLanguage: string;
};

type ShellState = {
  hasError: boolean;
  showConnectionAlert: boolean;
};

type BaseStylesProps = {
  shouldEnableHyphenation: boolean;
  fontSizesList: number[];
  theme: any;
};

const getFontSize = (originalSize: number, options?: FontSizeOptions) => (props: BaseStylesProps) =>
  props.theme.themeUtils.getFontSize(true, originalSize, options);

export const BaseStyles = createGlobalStyle<BaseStylesProps>`
  *, *::after {
    hyphens: ${props => (props.shouldEnableHyphenation ? 'auto' : 'none')};

    ${props =>
      props.fontSizesList
        .map(
          size =>
            `--font-size-${size}: ${getFontSize(size)(
              props
            )}; --font-size-${size}-skip-large: ${getFontSize(size, { skipLarge: true })(props)}`
        )
        .join(';')};

    --font-size-10-cover-page: ${getFontSize(10, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-11-cover-page: ${getFontSize(11, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-12-cover-page: ${getFontSize(12, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-14-cover-page: ${getFontSize(14, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-16-cover-page: ${getFontSize(16, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-18-cover-page: ${getFontSize(18, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-20-cover-page: ${getFontSize(20, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-22-cover-page: ${getFontSize(22, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-24-cover-page: ${getFontSize(24, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-26-cover-page: ${getFontSize(26, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-28-cover-page: ${getFontSize(28, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-48-cover-page: ${getFontSize(48, { page: PAGE_NAME.COVER_PAGE })};
    --font-size-72-cover-page: ${getFontSize(72, { page: PAGE_NAME.COVER_PAGE })};
    
    --font-size-48-cover-page-skip-large: ${getFontSize(48, {
      page: PAGE_NAME.COVER_PAGE,
      skipLarge: true
    })};
  }
`;
export class Shell extends React.Component<ShellProps, ShellState> {
  constructor(props: ShellProps) {
    super(props);
    this.state = {
      hasError: false,
      showConnectionAlert: false
    };
  }

  windowResizeHandler = throttle(() => {
    this.props.actions.resolutionChanged();
  }, 500);

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResizeHandler);
    this.props.actions.resolutionChanged();

    await this.props.actions.load();
  }

  componentDidUpdate(prevProps: ShellProps): void {
    if (this.props.isOffline && prevProps.isOffline !== this.props.isOffline) {
      this.setState({ showConnectionAlert: true });
    }

    if (prevProps.isOffline && prevProps.isOffline !== this.props.isOffline) {
      setTimeout(() => {
        this.setState({
          showConnectionAlert: false
        });
      }, 2000);
    }
  }

  shouldEnableHyphenation = () => {
    const { selectedLanguage = 'en' } = this.props;
    return HYPHENATED_LANGUAGES.includes(selectedLanguage);
  };

  componentDidCatch(error: any, errorInfo: any) {
    console.error(`Component did catch: ${error}, ${errorInfo}`);
    this.setState({ hasError: true });
  }

  checkForErrors = () => {
    const { isLrsMisconfigurationError, isOffline, isLoadingFailed } = this.props;
    const { showConnectionAlert, hasError } = this.state;
    if (showConnectionAlert) {
      return (
        <CommonError
          isOffline={isOffline}
          isConnectionError={true}
          data-test={'close-course-connection-lost'}
          title={localize('[check connection]')}
          message={localize('[close course connection lost]')}
        />
      );
    }
    if (isLrsMisconfigurationError) {
      return (
        <CommonError
          isOffline={isOffline}
          isConnectionError={false}
          data-test={'invalid-lrs-configuration'}
          title={localize('[configuration error]')}
          message={localize('[invalid lrs configuration]')}
        />
      );
    }

    if (isLoadingFailed || hasError) {
      return <SomethingWentWrong hasUnhandledError={hasError} />;
    }

    return null;
  };

  render() {
    const { isLoading, fontSizesList = [], colors, fonts, accessibilitySettings } = this.props;
    const errorPage = this.checkForErrors();
    return (
      <ThemeProvider
        theme={{ colors, fonts, themeUtils: getThemeUtils({ accessibilitySettings }) }}
      >
        {(isLoading && <CircleLoader iconSize={96} />) || errorPage || (
          <React.Suspense fallback={<CircleLoader iconSize={96} />}>
            {this.props.isResource ? <Resource {...this.props} /> : <Course {...this.props} />}
          </React.Suspense>
        )}
        <BaseStyles
          shouldEnableHyphenation={this.shouldEnableHyphenation()}
          fontSizesList={[...new Set<number>([...fontSizesList, ...FONT_SIZES_LIST])]}
        />
      </ThemeProvider>
    );
  }
}
function mapStateToProps(state: RootAppState) {
  return {
    colors: getColors(state),
    fonts: getFonts(state),
    isOffline: isOffline(state),
    isLrsMisconfigurationError: isLrsMisconfigurationError(state),
    isLoading: isAppLoading(state),
    isLoadingFailed: isAppLoadingFailed(state),
    isLowResolution: state.app.isLowResolution,
    isTocExpanded: getTreeOfContentVisibility(state),
    background: getBackground(state),
    isUserAuthenticated: isAuthenticated(state),
    userAccessIsLimited: isCourseAccessLimited(state),
    fontSizesList: getFontSizesList(state),
    accessibilitySettings: getAccessibilitySettings(state),
    isResource: isResource(state),
    selectedLanguage: getSelectedLanguage(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  withNavigation(connect(mapStateToProps, mapDispatchToProps)(withScrollOnRedirect(Shell)))
);
