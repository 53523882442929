import * as React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider, withTheme } from 'styled-components';
import { bindActionCreators, Dispatch } from 'redux';
import 'react-toastify/dist/ReactToastify.css';
import * as userActions from 'store/user/actions';
import { isAppLoadingFailed } from 'store/app/selectors';
import {
  shouldSaveCrossDevice,
  isAnonymous,
  isGuestUser,
  isAuthenticated,
  getFullName,
  getEmail
} from 'store/user/selectors';
import {
  isScormMode,
  isNpsEnabled,
  getLearnServiceUrl,
  isPreviewMode,
  getTimerEnabled,
  isTrackingEnabled
} from 'store/settings/selectors';
import { isFinalized, isCourseLaunched } from 'store/course/selectors';
import * as popupActions from 'store/popup/actions';
import * as courseActions from 'store/course/actions';
import * as navigationActions from 'store/navigation/actions';
import * as accessibilityActions from 'store/accessibility/actions';
import Icon from 'components/common/Icon';
import { localize } from 'core/localization';
import {
  POPUP_ID_CONTINUE_LATER,
  POPUP_ID_CONTINUE_LATER_IN_SCORM,
  POPUP_ID_CLOSE_COURSE,
  POPUP_ID_NPS_POPUP,
  POPUP_ID_ACCESSIBILITY_DESCRIPTION
} from 'constants/popups';
import { Tooltip } from 'components/common';
import Timer from 'components/timer';
import { RootAppState } from 'store/types';
import { isLtiInitialized } from 'store/modules/selectors';
import { KEYS } from 'constants/common';
import Accessibility from 'components/accessibilitySettings';
import ContinueLater from '../popups/continueLater';
import ContinueLaterInScorm from '../popups/continueLaterInScorm';
import CloseCoursePopup from '../popups/closeCourse';
import NpsPopup from '../popups/nps';

import {
  MenuContainer,
  ExpandableWrapper,
  AvatarLetterContainer,
  AvatarLetterElement,
  IconContainer,
  ActionListContainer,
  ListItem,
  ItemText,
  TimerContainer,
  ItemActionContainer,
  Line,
  AccessibilityEnabledIcon,
  NameText,
  EmailText,
  UserDetailsContainer,
  LineContainer
} from './UserMenu.styled';
import { getAvatarLetter } from '../../store/user/selectors';
import { getAccessibilitySettings } from 'store/accessibility/selectors';
import { AccessibilityState } from 'store/accessibility/types';
import { accessibilityModeEnabled, isAccessibilityEnabled } from 'utils/comparison';
import { getAccessibilityExpanded } from 'store/popup/selectors';
import AccessibilityDescription from 'components/popups/accessibilityDescription/AccessibilityDescription';
import { USER_MENU_ICON_SIZES } from 'constants/accessibility';

const constants = {
  defaultAvatarLetter: 'A'
};

const closeCoursePopupSettings = {
  popupId: POPUP_ID_CLOSE_COURSE,
  popupAriaLabelKey: '[aria label close course popup]',
  component: CloseCoursePopup,
  disablePopupClosing: true
};

const npsPopupSettings = {
  popupId: POPUP_ID_NPS_POPUP,
  popupAriaLabelKey: '[aria label close nps popup]',
  component: NpsPopup,
  fromDownloadCertificateButton: false,
  disablePopupClosing: true,
  disableBottomLine: true
};

type UserMenuProps = {
  actions: { [key: string]: any };
  courseAction: { [key: string]: any };
  saveCrossDevice: boolean;
  avatarLetter: string;
  isScorm: boolean;
  isLti: boolean;
  previewMode: boolean;
  courseIsFinalized: boolean;
  npsEnabled: boolean;
  popupActions: { [key: string]: any };
  navigationActions: { [key: string]: any };
  theme: any;
  ariaHidden?: any;
  learnServiceUrl?: string;
  isLoadingFailed: boolean;
  hasUnhandledError: boolean;
  hasCourseLaunched: boolean;
  isTimerEnabled: boolean;
  isAnonymous: boolean;
  isGuestUser: boolean;
  isUserAuthenticated: boolean;
  accessibilityActions: any;
  accessibilitySettings: AccessibilityState;
  fullName: string;
  email: string;
  showMyCourses: boolean;
  showUserDetails: boolean;
  showContinueLater: boolean;
  isAccessibilityExpanded: boolean | null;
  showAccessibilityToast: any;
  isTrackingEnabled: boolean;
};

type UserMenuState = {
  isExpanded: boolean;
  isAccessibilityEnabled: boolean;
  isAccessibilityUpdated: boolean;
};

type AccessibilityIconStyle = {
  color: string;
  opacity: number;
};

export class UserMenu extends React.Component<UserMenuProps, UserMenuState> {
  templateTheme: any;

  actionListContainerRef: any;

  expandableWrapperRef: any;

  toolTipChildren: Array<any>;

  constructor(props: UserMenuProps) {
    super(props);

    const {
      isAnonymous,
      isGuestUser,
      isUserAuthenticated,
      accessibilitySettings,
      isTrackingEnabled
    } = props;
    let showAccessibilitySettings =
      isAnonymous || isGuestUser || !isUserAuthenticated || !isTrackingEnabled;
    this.toolTipChildren = [];

    if (props.isAccessibilityExpanded !== null) {
      showAccessibilitySettings = props.isAccessibilityExpanded;
    }
    this.updateAccessibilityExpanded(showAccessibilitySettings);
    this.state = {
      isExpanded: false,
      isAccessibilityEnabled: isAccessibilityEnabled(accessibilitySettings),
      isAccessibilityUpdated: false
    };
    this.keyDown = this.keyDown.bind(this);
    this.actionListContainerRef = React.createRef();
    this.expandableWrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown, false);
    document.getElementsByTagName('body')[0].addEventListener('click', this.onClickOutside, false);
  }

  componentDidUpdate(prevProps: UserMenuProps) {
    const { accessibilitySettings: prevAccessibilitySettings } = prevProps;
    const { accessibilitySettings } = this.props;

    const accessibilityEnabled = isAccessibilityEnabled(accessibilitySettings);
    if (this.state.isAccessibilityEnabled !== accessibilityEnabled) {
      this.setState({
        isAccessibilityEnabled: accessibilityEnabled,
        isAccessibilityUpdated: false
      });
    }
    if (accessibilityModeEnabled(prevAccessibilitySettings, accessibilitySettings)) {
      this.showAccessibilityToast();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false);
    document
      .getElementsByTagName('body')[0]
      .removeEventListener('click', this.onClickOutside, false);
  }

  toggle = () =>
    new Promise<void>(resolve => {
      this.setState(
        {
          isExpanded: !this.state.isExpanded
        },
        resolve
      );
    });

  toggleAccessibilityMode = () => {
    const { isAccessibilityExpanded } = this.props;
    this.updateAccessibilityExpanded(!isAccessibilityExpanded);
  };

  updateAccessibilityExpanded = (isAccessibilityExpanded: boolean) => {
    const { popupActions } = this.props;
    if (isAccessibilityExpanded) {
      popupActions.openAccessibilityAppMenuSection();
    } else {
      popupActions.closeAccessibilityAppMenuSection();
    }
  };

  closePopup = () => {
    this.setState({
      isExpanded: false
    });
  };

  keyDown(event: any) {
    if (
      event.key?.toLowerCase() === KEYS.ESCAPE.toLowerCase() ||
      (event.key?.toLowerCase() === KEYS.TAB.toLowerCase() &&
        this.state.isExpanded &&
        document
          .getElementById('action-list')
          ?.lastElementChild?.classList.contains('focus-visible'))
    ) {
      this.closePopup();
    }
  }

  onClickOutside = (event?: any) => {
    if (
      this.toolTipChildren &&
      this.actionListContainerRef.current &&
      this.expandableWrapperRef.current
    ) {
      const toastElements = [...document.getElementsByClassName('Toastify__toast')]
        .map(e => e.getElementsByTagName('*'))
        .reduce((a: any, c: any): any => [...a, ...c], []);
      this.toolTipChildren = [
        ...this.actionListContainerRef.current.getElementsByTagName('*'),
        ...this.expandableWrapperRef.current.getElementsByTagName('*'),
        ...toastElements
      ];

      if (this.toolTipChildren.length && !this.toolTipChildren.includes(event?.target)) {
        this.closePopup();
      }
    }
  };

  onLogout = async () => {
    await this.toggle();
    this.props.actions.notSkipAuthentication();
    await this.props.actions.logout();
  };

  onContinueLater = () => {
    this.props.popupActions.openPopup({
      popupId: POPUP_ID_CONTINUE_LATER,
      popupAriaLabelKey: '[continue later aria label]',
      component: ContinueLater
    });
    this.toggle();
  };

  onAccessibilityDescription = () => {
    this.props.popupActions.openPopup({
      popupId: POPUP_ID_ACCESSIBILITY_DESCRIPTION,
      popupAriaLabelKey: '[aria label wcag feature description]',
      component: AccessibilityDescription,
      disableBottomLine: true
    });
    this.toggle();
  };

  onContinueLaterInScorm = () => {
    this.props.popupActions.openPopup({
      popupId: POPUP_ID_CONTINUE_LATER_IN_SCORM,
      popupAriaLabelKey: '[continue later aria label]',
      component: ContinueLaterInScorm
    });
    this.toggle();
  };

  updateAccessibilitySettings = async (accessibilitySettings: any) => {
    this.setState(
      {
        isAccessibilityUpdated: true
      },
      async () => {
        await this.props.accessibilityActions.updateAccessibilitySettings({
          ...accessibilitySettings
        });
      }
    );
  };

  showAccessibilityToast = () => {
    if (this.state.isAccessibilityUpdated) {
      this.props.showAccessibilityToast();
      this.setState({
        isAccessibilityUpdated: false
      });
    }
  };

  onSubmitResult = async () => {
    const { npsEnabled } = this.props;
    await this.props.courseAction.finished();

    this.props.popupActions.openPopup(npsEnabled ? npsPopupSettings : closeCoursePopupSettings);

    if (!npsEnabled) {
      await this.props.courseAction.finalized();
    }
    this.toggle();
  };

  renderLine = () => (
    <LineContainer>
      <Line theme={this.props.theme} />
    </LineContainer>
  );

  renderListItem(
    clickAction: any,
    itemTextKey: any,
    iconSettings?: any,
    itemActionElement: any = '',
    additionalProps?: any
  ) {
    const { theme, accessibilitySettings } = this.props;

    return (
      <ListItem theme={theme} {...additionalProps} onClick={clickAction}>
        {iconSettings && (
          <IconContainer opacity={0.3}>
            <Icon
              theme={theme}
              name={iconSettings.name}
              size={iconSettings.size}
              color={iconSettings.color}
            />
          </IconContainer>
        )}
        <ItemText theme={theme} appearance="span" size={14} accessibility={accessibilitySettings}>
          {localize(itemTextKey)}
        </ItemText>
        <ItemActionContainer>{itemActionElement}</ItemActionContainer>
      </ListItem>
    );
  }

  renderMyCourses = () => {
    const {
      showMyCourses,
      learnServiceUrl = '',
      navigationActions,
      accessibilitySettings
    } = this.props;
    if (showMyCourses) {
      return this.renderListItem(
        () => navigationActions.goToUrl(learnServiceUrl),
        '[my courses]',
        {
          name: 'user',
          size: USER_MENU_ICON_SIZES[accessibilitySettings.fontSize],
          color: 'textColor'
        },
        null,
        {
          'data-test': 'my-courses-button'
        }
      );
    }

    return null;
  };

  renderContinueLater = () => {
    const { showContinueLater, accessibilitySettings } = this.props;
    if (showContinueLater && !this.shouldHideListItem()) {
      return (
        <>
          {this.renderListItem(
            this.onContinueLater,
            '[continue later with link]',
            {
              name: 'link-chain',
              size: USER_MENU_ICON_SIZES[accessibilitySettings.fontSize],
              color: 'textColor'
            },
            null,
            {
              'data-test': 'continue-later-button'
            }
          )}
          {this.renderLine()}
        </>
      );
    }

    return null;
  };

  renderAccessibilityMode = () => {
    const { isAccessibilityExpanded, theme, accessibilitySettings } = this.props;

    return this.renderListItem(
      this.toggleAccessibilityMode,
      '[accessibility mode]',
      {
        name: 'universal-access',
        size: USER_MENU_ICON_SIZES[accessibilitySettings.fontSize] + 1,
        color: 'textColor'
      },
      <IconContainer opacity={0.3}>
        <Icon
          theme={theme}
          name={isAccessibilityExpanded ? 'chevron-up' : 'chevron-down'}
          size={6}
          color="textColor"
        />
      </IconContainer>,
      {
        'data-test': 'accessibility-collapse-button',
        'aria-label': `Accessibility options ${isAccessibilityExpanded ? 'expanded' : 'collapsed'}`
      }
    );
  };

  renderLogoutButton = () => {
    const { isUserAuthenticated, isScorm, isAnonymous, isTrackingEnabled } = this.props;
    const itemTextKey = isAnonymous ? '[exit course]' : '[logout]';

    return isTrackingEnabled && isUserAuthenticated && !isScorm ? (
      <>
        {this.renderLine()}
        {this.renderListItem(
          this.onLogout,
          itemTextKey,
          {
            name: 'sign-out',
            size: 9,
            color: 'textColor'
          },
          null,
          {
            'data-test': 'logout-button'
          }
        )}
      </>
    ) : null;
  };

  getMenuStatus = () => {
    const { isExpanded } = this.state;

    return isExpanded ? 'expanded' : 'collapsed';
  };

  renderUserDetails = () => {
    const { showUserDetails, fullName, email, theme } = this.props;
    if (showUserDetails && fullName) {
      return (
        <>
          <UserDetailsContainer>
            <NameText theme={theme}>{fullName}</NameText>
            <EmailText theme={theme}>{email}</EmailText>
          </UserDetailsContainer>
          {this.renderLine()}
        </>
      );
    }

    return null;
  };

  getAccessibilityIconStyle = (opacity = 0.7): AccessibilityIconStyle => {
    const style: AccessibilityIconStyle = { color: 'textColor', opacity };
    if (this.state.isAccessibilityEnabled) {
      style.color = 'mainColor';
      style.opacity = 1;
    }
    if (this.props.accessibilitySettings.isContrastTheme) {
      style.color = 'ctaButtonColor';
    }

    return style;
  };

  render() {
    const {
      avatarLetter,
      isScorm,
      courseIsFinalized,
      ariaHidden,
      hasCourseLaunched,
      isTimerEnabled,
      isUserAuthenticated,
      accessibilitySettings,
      isAccessibilityExpanded,
      isTrackingEnabled,
      theme
    } = this.props;
    const { isExpanded, isAccessibilityEnabled } = this.state;

    return (
      <MenuContainer ariaHidden={ariaHidden}>
        {isTimerEnabled && hasCourseLaunched && (
          <TimerContainer data-test="course-timer-container">
            <Timer />
          </TimerContainer>
        )}
        <Tooltip
          classNames={'user-menu'}
          trigger={'manual'}
          open={isExpanded}
          arrow={false}
          position={'bottom-end'}
          style={{ display: 'block' }}
          distance={6}
          interactive
          html={
            <ThemeProvider theme={theme}>
              <ActionListContainer
                id="action-list"
                ref={this.actionListContainerRef}
                accessibility={accessibilitySettings}
              >
                {this.renderUserDetails()}
                {this.renderMyCourses()}
                {this.renderContinueLater()}
                {isUserAuthenticated &&
                  !this.shouldHideListItem() &&
                  isScorm &&
                  (courseIsFinalized
                    ? this.renderListItem(this.onSubmitResult, '[user menu submit result]', {
                        name: 'submit-results',
                        size: USER_MENU_ICON_SIZES[accessibilitySettings.fontSize],
                        color: 'textColor'
                      })
                    : this.renderListItem(this.onContinueLaterInScorm, '[continue course later]', {
                        name: 'continue-course-later',
                        size: USER_MENU_ICON_SIZES[accessibilitySettings.fontSize],
                        color: 'textColor'
                      }))}
                {this.renderAccessibilityMode()}
                {isAccessibilityExpanded ? this.renderLine() : ''}
                <Accessibility
                  accessibilitySettings={accessibilitySettings}
                  visible={isAccessibilityExpanded || false}
                  updateAccessibilitySettings={this.updateAccessibilitySettings}
                  onAccessibilityDescription={this.onAccessibilityDescription}
                />
                {this.renderLogoutButton()}
              </ActionListContainer>
            </ThemeProvider>
          }
        >
          <ExpandableWrapper
            ariaHidden={false}
            onClick={this.toggle}
            tabIndex={0}
            ref={this.expandableWrapperRef}
            isContrastTheme={accessibilitySettings.isContrastTheme}
          >
            <AvatarLetterContainer
              backgroundColor={isUserAuthenticated && isTrackingEnabled ? '' : 'transparent'}
            >
              {isAccessibilityEnabled && isUserAuthenticated && isTrackingEnabled && (
                <AccessibilityEnabledIcon
                  theme={theme}
                  name={`universal-access`}
                  size={14}
                  color={`buttonTextColor`}
                  accessibility={accessibilitySettings}
                />
              )}
              <AvatarLetterElement>
                {isUserAuthenticated && isTrackingEnabled ? (
                  avatarLetter || constants.defaultAvatarLetter
                ) : (
                  <IconContainer
                    opacity={this.getAccessibilityIconStyle().opacity}
                    aria-label={`accessibility settings ${this.getMenuStatus()}`}
                    id={'accessibility-login-icon-container'}
                  >
                    <Icon
                      name={'universal-access'}
                      size={20}
                      color={this.getAccessibilityIconStyle().color}
                    />
                  </IconContainer>
                )}
              </AvatarLetterElement>
            </AvatarLetterContainer>
            <IconContainer opacity={this.getAccessibilityIconStyle(0.3).opacity}>
              <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} size={5} color="textColor" />
            </IconContainer>
          </ExpandableWrapper>
        </Tooltip>
      </MenuContainer>
    );
  }

  shouldHideListItem() {
    return this.props.isLoadingFailed || this.props.hasUnhandledError;
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    avatarLetter: getAvatarLetter(state),
    fullName: getFullName(state),
    email: getEmail(state),
    saveCrossDevice: shouldSaveCrossDevice(state),
    isScorm: isScormMode(state),
    isLti: isLtiInitialized(state),
    courseIsFinalized: isFinalized(state),
    npsEnabled: isNpsEnabled(state),
    learnServiceUrl: getLearnServiceUrl(state),
    previewMode: isPreviewMode(state),
    isLoadingFailed: isAppLoadingFailed(state),
    hasCourseLaunched: isCourseLaunched(state),
    isTimerEnabled: getTimerEnabled(state),
    isAnonymous: isAnonymous(state),
    isGuestUser: isGuestUser(state),
    isUserAuthenticated: isAuthenticated(state),
    accessibilitySettings: getAccessibilitySettings(state),
    isTrackingEnabled: isTrackingEnabled(state),
    showMyCourses:
      isTrackingEnabled(state) &&
      isAuthenticated(state) &&
      !isScormMode(state) &&
      !isPreviewMode(state) &&
      !isLtiInitialized(state) &&
      !isAnonymous(state) &&
      !isGuestUser(state),
    showUserDetails:
      isTrackingEnabled(state) &&
      !isAnonymous(state) &&
      !(isGuestUser(state) && !isScormMode(state)),
    showContinueLater:
      isTrackingEnabled(state) &&
      isAuthenticated(state) &&
      !isScormMode(state) &&
      shouldSaveCrossDevice(state),
    isAccessibilityExpanded: getAccessibilityExpanded(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
    courseAction: bindActionCreators(courseActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch),
    popupActions: bindActionCreators(popupActions, dispatch),
    accessibilityActions: bindActionCreators(accessibilityActions, dispatch)
  };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(UserMenu));
