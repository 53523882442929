import courseMapper from 'core/mappers/course';
import { guidWithHyphens } from 'utils/object';
import * as questionActions from 'store/questions/actions';
import * as contentsActions from 'store/contents/actions';
import * as sectionActions from 'store/sections/actions';
import {
  initializeScorm,
  initializeXapi,
  subscribeXapi,
  unsubscribeXapi
} from 'store/modules/actions';
import * as navigationActions from 'store/navigation/actions';
import { isXapiInitialized, shouldInitializeXapi } from 'store/modules/selectors';
import {
  getAffectProgressSections,
  getSections,
  isSectionsFailed,
  isSectionsPassed
} from 'store/sections/selectors';
import { getValueFromUrl } from 'utils/window';
import { hashData } from 'utils/compression';
import {
  getAttemptId,
  getCourse,
  getCourseAttempt,
  getPreviewQuestionId,
  getStatus,
  getTimerProgressKey,
  getTimeSpent,
  hasBeenContinued,
  hasBeenStarted,
  isCourseAccessLimited
} from 'store/course/selectors';
import eventEmitter, { events } from 'core/events/eventEmitter';
import { getUser, isAnonymous } from 'store/user/selectors';
import {
  filterStatementsInformationContent,
  getAllQuestionsAnswers,
  getAffectProgressQuestions,
  getCorrectlyAffectProgressAnsweredQuestionsCount,
  getFirstIncorrectOrPendingQuestion,
  getQuestion,
  getQuestions,
  getQuestionSection,
  getStatementsData,
  hasAnyQuestionOpened,
  isAllQuestionsFailed,
  isAllQuestionsTimedOut,
  getFirstIncorrectQuestionPreviousAttemptFromPool
} from 'store/questions/selectors';

import sampleSize from 'lodash.samplesize';
import shuffle from 'utils/shuffle';
import progressStorage from 'core/progressStorage';
import { INDEX_PATH, QUESTION_PATH } from 'constants/navigation';
import { DEFAULT_QUESTION_POOL_ID_LENGTH } from 'constants/common';
import { getQuestionUrl } from 'utils/navigation';
import { ProgressStatus } from 'constants/progressStatus';
import { PROGRESS_STORAGE_TIMER_UPDATE_INTERVAL, TimerStatus } from 'constants/timer';
import * as courseTimerActions from 'store/timer/actions';
import { TimerState } from 'store/timer/types';
import {
  buildQuestionWithRandomizedOptions,
  buildRandomizedOptions
} from 'core/dataProcessors/questionOptions';
import {
  getMasteryScoreValue,
  getQuestionPoolSize,
  getTimerEnabled,
  isAllQuestionsOnOnePage,
  isAnswersFromPreviousAttemptEnabled,
  isOverallMasteryScore,
  isPreviewMode,
  isQuestionPoolEnabled,
  isQuestionRandomizeEnabled,
  isRandomizeAnswersEnabled,
  isReviewMode,
  isScoringOfContentPagesAllowed,
  isScormMode,
  isShowContentPagesEnabled,
  shouldSubmitAllQuestions,
  getXapiSettings
} from '../settings/selectors';

import { defaultLrsName } from 'core/xApi/constants';
import { ActionTypes } from './types';
import { ThunkResult } from '../types';
import { arrayToObject } from '../../utils/object';
import { hasGuidedReattempt } from '../common/selectors';
import {
  restoreAccessibilitySettings,
  updateAccessibilitySettings
} from 'store/accessibility/actions';
import contentLoader from 'core/http/contentLoader';
import pathToRegexp from 'path-to-regexp';

const buildQuestionPool = (questions: any[], questionPoolIds: string[]): any[] => {
  let tempQuestion;
  const sortedQuestions = [];
  sortedQuestions.push(
    ...questionPoolIds.map(questionPoolId => {
      tempQuestion = questions.find(
        question => question.id.slice(0, questionPoolId.length) === questionPoolId
      );
      if (tempQuestion === undefined) {
        throw new Error('Question not found');
      }
      tempQuestion.isPoolQuestion = true;
      return tempQuestion;
    })
  );

  sortedQuestions.push(
    ...questions.filter(question => {
      if (!questionPoolIds.includes(question.id.slice(0, questionPoolIds[0].length))) {
        question.isPoolQuestion = false;
        return question;
      }
      return false;
    })
  );

  return sortedQuestions;
};

export const loadPoolQuestions = (
  questions: any[],
  questionPoolIds: string[]
): ThunkResult<Promise<void>> => async dispatch => {
  let builtQuestions = [...buildQuestionPool(questions, questionPoolIds)];
  const builtPoolIds: string[] = [];
  builtQuestions = builtQuestions.map(question => {
    if (question.isPoolQuestion) {
      builtPoolIds.push(question.id);
    }
    return [question.id, { ...question }];
  });

  const questionsObject = arrayToObject(builtQuestions);
  dispatch(questionActions.questionsLoaded(questionsObject));
  dispatch({
    type: ActionTypes.POOL_IDS_LOADED,
    payload: {
      poolIds: builtPoolIds
    }
  });
  dispatch(navigationActions.poolUrlReset(questionsObject));
};

const getShortenedIds = (questions: any[], idLength = DEFAULT_QUESTION_POOL_ID_LENGTH): any => {
  const maxIdLength = questions[0]?.id.length;
  let shortenedIds: string[] = [];

  idLength = idLength > maxIdLength ? maxIdLength : idLength;
  for (let i = idLength; i <= maxIdLength; i++) {
    const uniqueShortenedIds = new Set(shortenedIds);
    if (uniqueShortenedIds.size === questions.length) {
      return shortenedIds;
    }
    shortenedIds = questions.map(question => question.id.slice(0, i));
  }

  return shortenedIds;
};

export const clearPreviewQuestionId = (): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_PREVIEW_QUESTION_ID });
};

export const markCourseAsPassed = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const affectProgressSections = getAffectProgressSections(getState());

  affectProgressSections.forEach(section => dispatch(sectionActions.onSectionIsPassed(section.id)));

  await dispatch({
    type: ActionTypes.COURSE_IS_PASSED,
    payload: {
      status: ProgressStatus.PASSED
    }
  });

  const course = getCourse(getState());
  await eventEmitter.emit(events.COURSE_SUBMIT, {
    statementsData: [
      {
        course: {
          ...getState(),
          course,
          timeSpent: getTimeSpent(getState()),
          xapiStatus: course.status
        }
      }
    ],
    state: getState()
  });

  eventEmitter.emit(events.COURSE_COMPLETED);
};

const courseCompleted = (
  newCourseStatus: number,
  oldCourseStatus: number
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (newCourseStatus !== oldCourseStatus && shouldSubmitAllQuestions(getState()) === false) {
    eventEmitter.emit(events.COURSE_COMPLETED);
  }
};

export const updateScorePerCourse = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const affectProgressQuestions = getAffectProgressQuestions(getState());
  const masteryScore = getMasteryScoreValue(getState());

  const correctlyAnsweredQuestionsCount = getCorrectlyAffectProgressAnsweredQuestionsCount(
    getState()
  );

  const score =
    affectProgressQuestions.length > 0
      ? Math.floor((correctlyAnsweredQuestionsCount * 100) / affectProgressQuestions.length)
      : 100;

  let status = ProgressStatus.IN_PROGRESS;

  if (isAllQuestionsFailed(getState()) || isAllQuestionsTimedOut(getState())) {
    status = ProgressStatus.FAILED;
    dispatch(courseCompleted(status, getStatus(getState())));
  }

  if (score >= masteryScore) {
    status = ProgressStatus.PASSED;
    dispatch(courseCompleted(status, getStatus(getState())));
  }

  dispatch({
    type: ActionTypes.COURSE_SCORE_UPDATED,
    payload: {
      score,
      status
    }
  });
};

export const updateScorePerSection = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const sections = getAffectProgressSections(getState());
  const totalScore = sections.reduce((sum, section) => sum + section.score, 0);
  const score = sections.length > 0 ? Math.floor(totalScore / sections.length) : 100;
  let status = ProgressStatus.IN_PROGRESS;

  if (isSectionsFailed(getState())) {
    status = ProgressStatus.FAILED;
    dispatch(courseCompleted(status, getStatus(getState())));
  }
  if (isSectionsPassed(getState())) {
    status = ProgressStatus.PASSED;
    dispatch(courseCompleted(status, getStatus(getState())));
  }

  dispatch({
    type: ActionTypes.COURSE_SCORE_UPDATED,
    payload: {
      score,
      status
    }
  });
};

export const makeSubmitAtOnce = (): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch({
    type: ActionTypes.COURSE_SUBMIT_AT_ONCE
  });
};

export const updateSubmitAtOnceAttemptNumber = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const courseAttempt = getCourseAttempt(getState()) ? getCourseAttempt(getState()) + 1 : 1;
  dispatch({
    type: ActionTypes.COURSE_UPDATE_ATTEMPTS,
    payload: {
      courseAttempt
    }
  });
  await eventEmitter.emit(events.COURSE_UPDATE_ATTEMPTS, courseAttempt);
};

export const courseAttemptedSubmitAtOnce = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({
    type: ActionTypes.COURSE_REATTEMPTED_SUBMIT_AT_ONCE
  });
};

const setTimeSpent = (value: number): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch({
    type: ActionTypes.TIME_SPENT_UPDATED,
    payload: {
      timeSpent: {
        accumulatedValueInMilliseconds: value,
        incrementStartedAt: new Date()
      }
    }
  });
};

const getClickedId = (url: any) => {
  const clickedId = url.split('/');
  if (clickedId[clickedId.length - 1] === 'learning-objective') {
    return clickedId.slice(-2, -1)[0];
  }
  return clickedId.slice(-1)[0];
};

export const setTimerProgress = (
  timerProgress: TimerState = { elapsed: 0, status: TimerStatus.NOT_STARTED }
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (timerProgress.status === TimerStatus.RESET) {
    localStorage.setItem(getTimerProgressKey(getState()), '0');
  }

  const localStorageTimerProgress = localStorage.getItem(getTimerProgressKey(getState())) || '0';
  const elapsedTime = timerProgress.elapsed || 0;
  const elapsedTimeDelta = parseInt(localStorageTimerProgress, 10) - elapsedTime;

  if (
    elapsedTime > 0 ||
    (elapsedTimeDelta > 0 && elapsedTimeDelta < PROGRESS_STORAGE_TIMER_UPDATE_INTERVAL) ||
    hasAnyQuestionOpened(getState())
  ) {
    dispatch(
      courseTimerActions.setTimerStatus(
        timerProgress.status === TimerStatus.NOT_STARTED
          ? TimerStatus.STARTED
          : timerProgress.status
      )
    );
    dispatch(
      courseTimerActions.setTimerElapsed(
        Math.max(timerProgress.elapsed, parseInt(localStorageTimerProgress, 10))
      )
    );
    localStorage.setItem(
      getTimerProgressKey(getState()),
      `${Math.max(timerProgress.elapsed, parseInt(localStorageTimerProgress, 10))}`
    );
  } else if (timerProgress.status === TimerStatus.STARTED) {
    dispatch(courseTimerActions.setTimerStatus(timerProgress.status));
    dispatch(courseTimerActions.setTimerElapsed(timerProgress.elapsed));
  }
};

export const updateScore = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const masteryScorePerCourse = isOverallMasteryScore(getState());

  if (masteryScorePerCourse) {
    dispatch(updateScorePerCourse());
  } else {
    const isQuestionPoolAllowed = isQuestionPoolEnabled(getState());
    if (!isQuestionPoolAllowed) {
      dispatch(updateScorePerSection());
    }
  }
};

export const updateProgress = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch(updateScore());
};

export const load = (data: any): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.COURSE_DATA_LOADING_STARTED });
  try {
    const showContentPages = isShowContentPagesEnabled(getState());
    const { course, sections, questions, contents } = courseMapper.map(data, showContentPages);

    if (getValueFromUrl('questionId')) {
      course.previewQuestionId = getValueFromUrl('questionId');
    }
    dispatch(questionActions.questionsLoaded(questions));
    dispatch(sectionActions.sectionsLoaded(sections));
    await dispatch(contentsActions.contentsLoaded(contents));
    await dispatch({ type: ActionTypes.COURSE_DATA_LOADED, payload: course });
    eventEmitter.emit(events.APP_INITIALIZED, {
      state: getState(),
      isScormMode: isScormMode(getState())
    });
  } catch (e) {
    dispatch({ type: ActionTypes.COURSE_DATA_LOADING_FAILED, reason: e });
    throw e;
  }
};

export const processPoolQuestions = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const inProgressQuestionPool = progressStorage.getQuestionPool();
  const questions = getQuestions(getState());
  const questionPoolSize = getQuestionPoolSize(getState());
  const nonSurveyQuestions = questions.filter(question => !question.isSurvey);
  if (inProgressQuestionPool && inProgressQuestionPool.length > 0) {
    await dispatch(loadPoolQuestions(questions, inProgressQuestionPool));
  } else {
    const randomQuestionsSubset = sampleSize(nonSurveyQuestions, questionPoolSize);
    // shortening ids to save on the storage space(4Kb) for suspend_data in scorm enabled lms
    const questionPoolIds = getShortenedIds(randomQuestionsSubset);
    await dispatch(loadPoolQuestions(questions, questionPoolIds));
    await eventEmitter.emit(events.COURSE_QUESTION_POOL_SELECTED, {
      questionPoolIds,
      state: getState()
    });
  }
};

export const isQuestionPoolSizeChanged = (): ThunkResult<boolean> => (dispatch, getState) => {
  const inProgressQuestionPool = progressStorage.getQuestionPool();
  const questionPoolSize = getQuestionPoolSize(getState());

  if (
    isQuestionPoolEnabled(getState()) &&
    inProgressQuestionPool.length &&
    questionPoolSize !== inProgressQuestionPool.length
  ) {
    return true;
  }

  return false;
};

const getOriginalQuestionsOrder = async (state: any) => {
  const materialData = await contentLoader.loadMaterialData();
  const showContentPages = isShowContentPagesEnabled(state);
  const { questions } = courseMapper.map(materialData, showContentPages);
  return questions;
};

export const processCourseChecksum = (): ThunkResult => async (dispatch, getState) => {
  if (dispatch(isQuestionPoolSizeChanged())) {
    throw new Error('Question Pool size changed.');
  }

  const questions = await getOriginalQuestionsOrder(getState());
  const originalQuestionsState = { ...getState(), questions };
  const courseStructure = getAllQuestionsAnswers(originalQuestionsState);

  const courseChecksum = hashData(courseStructure);
  const progressCourseCompress = progressStorage.getCourseChecksum();
  if (progressCourseCompress) {
    if (courseChecksum !== progressCourseCompress) {
      throw new Error('Course has been updated!');
    }
  } else {
    eventEmitter.emit(events.COURSE_CHECKSUM_UPDATED, {
      courseChecksum: courseChecksum,
      state: getState()
    });
  }
};

export const loadAnswers = (showPrevAnswer: boolean): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const questions = getQuestions(getState());
  const answers = progressStorage.getAnswers(questions);
  const sectionIds = new Set();
  for (const { id, response, attempt } of answers) {
    const question = getQuestion(getState(), id);
    sectionIds.add(question.sectionId);
    dispatch(questionActions.restoreProgress(id, response, attempt, showPrevAnswer));
  }

  if (getTimerEnabled(getState())) {
    await dispatch(setTimerProgress(progressStorage.timer));
  }

  if (!isQuestionPoolEnabled(getState())) {
    for (const sectionId of sectionIds) {
      dispatch(sectionActions.updateSection(sectionId as string));
    }
  }
  await dispatch(updateScore());
};

export const processRandomizeAnswers = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const storedRandomizedAnswersList = progressStorage.getRandomizeAnswers();
  const questions = getQuestions(getState());
  if (storedRandomizedAnswersList?.length > 0) {
    dispatch(
      questionActions.questionsLoaded(
        buildQuestionWithRandomizedOptions(getQuestions(getState()), storedRandomizedAnswersList)
      )
    );
  } else {
    const randomizedOptionsList = buildRandomizedOptions(questions);
    dispatch(
      questionActions.questionsLoaded(
        buildQuestionWithRandomizedOptions(getQuestions(getState()), randomizedOptionsList)
      )
    );
    await eventEmitter.emit(events.COURSE_RANDOMIZED_OPTIONS_SAVED, {
      randomizedOptionsList,
      state: getState()
    });
  }
  dispatch({
    type: ActionTypes.COURSE_RANDOMIZED_OPTIONS_LOADED
  });
};

export const setCourseAttempt = (attempt: number): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch({
    type: ActionTypes.COURSE_UPDATE_ATTEMPTS,
    payload: {
      courseAttempt: attempt
    }
  });
};

export const setCourseAttemptOnTimeOut = (): ThunkResult<Promise<void>> => async dispatch => {
  try {
    await progressStorage.restoreProgress();
    if (progressStorage?.courseAttemptNumber) {
      dispatch(setCourseAttempt(progressStorage.courseAttemptNumber));
    }
  } catch (e) {
    console.error(e);
  }
};

export const restoreProgress = (
  isProgressFetched: boolean
): ThunkResult<Promise<boolean>> => async (dispatch, getState): Promise<boolean> => {
  try {
    const isQuestionPoolAllowed = isQuestionPoolEnabled(getState());

    if (isQuestionPoolAllowed) {
      await dispatch(processPoolQuestions());
    }
    dispatch({ type: ActionTypes.COURSE_PROGRESS_RESTORE_FETCHED });

    const randomizeAnswersEnabled = isRandomizeAnswersEnabled(getState());

    if (randomizeAnswersEnabled) {
      await dispatch(processRandomizeAnswers());
    }

    if (!isProgressFetched) {
      return false;
    }

    const isCourseOnOnePage = isAllQuestionsOnOnePage(getState());
    const isSubmitAllAtOnce = shouldSubmitAllQuestions(getState());

    if (isSubmitAllAtOnce) {
      const { allQuestionsSubmitted } = progressStorage;
      const { courseAttemptNumber } = progressStorage;
      await dispatch(setCourseAttempt(courseAttemptNumber));
      if (allQuestionsSubmitted) {
        await dispatch(makeSubmitAtOnce());
      }
    }
    await dispatch(loadAnswers(false));

    if (progressStorage.timeSpent) {
      await dispatch(setTimeSpent(progressStorage.timeSpent));
    }

    if (
      isCourseOnOnePage &&
      progressStorage.url &&
      pathToRegexp(QUESTION_PATH).test(progressStorage.url)
    ) {
      await dispatch(
        navigationActions.updateElementIdInViewPort(getClickedId(progressStorage.url))
      );
    }

    dispatch({
      type: ActionTypes.COURSE_PROGRESS_RESTORED,
      payload: { attemptId: progressStorage.attemptId }
    });

    dispatch(
      updateAccessibilitySettings(
        await progressStorage.mergeAccessibilitySettings(
          await progressStorage.loadLocalAccessibilitySettings()
        )
      )
    );

    return true;
  } catch (e) {
    dispatch({ type: ActionTypes.COURSE_PROGRESS_RESTORE_FAILED, reason: e });
    eventEmitter.emit(events.COURSE_PROGRESS_RESTORE_FAILED);
    return false;
  }
};

export const onCourseLaunched = (attemptId?: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.COURSE_LAUNCHED,
    payload: { attemptId }
  });
  eventEmitter.emit(events.COURSE_LAUNCHED, attemptId);
};

export const onCourseStarted = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  try {
    await dispatch({
      type: ActionTypes.COURSE_STARTED
    });
    await dispatch(setTimeSpent(0));
    await eventEmitter.emit(events.COURSE_STARTED);
  } catch (e) {}
};

const loadScormMode = (isReAttempt: boolean): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  if (isScormMode(getState())) {
    await dispatch(initializeScorm(isReAttempt));
    await progressStorage.checkScormNonAsciiSupport();
  } else {
    if (isAnonymous(getState()) && !isReAttempt) {
      await eventEmitter.emit(events.APP_INITIALIZED, {
        state: getState(),
        isScormMode: isScormMode(getState())
      });
    }
    await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));
  }
};

const loadPreviewMode = (): ThunkResult<Promise<string>> => async (dispatch, getState) => {
  const isQuestionPoolAllowed = isQuestionPoolEnabled(getState());
  if (isQuestionPoolAllowed) {
    await dispatch(processPoolQuestions());
  }
  dispatch(onCourseLaunched());
  dispatch(onCourseStarted());
  const previewQuestionId = getPreviewQuestionId(getState());
  const randomizeAnswersEnabled = isRandomizeAnswersEnabled(getState());
  if (randomizeAnswersEnabled) {
    await dispatch(processRandomizeAnswers());
  }
  if (previewQuestionId) {
    const previewSectionId = getQuestionSection(getState(), previewQuestionId);
    const questionUrl = getQuestionUrl(previewSectionId, previewQuestionId);
    dispatch(clearPreviewQuestionId());
    return questionUrl;
  }
  return INDEX_PATH;
};

const loadPreviouslyAnsweredQuestions = (
  restoreStatus: boolean
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (
    !restoreStatus &&
    isAnswersFromPreviousAttemptEnabled(getState()) &&
    shouldSubmitAllQuestions(getState())
  ) {
    const isAnswersInProgress = await progressStorage.restoreAnswers();
    if (isAnswersInProgress) {
      await dispatch(loadAnswers(true));
    }
  }
};

export const isCourseEmpty = async (state: any) => {
  const isQuestionPoolAllowed = isQuestionPoolEnabled(state);
  const questionPoolSize = getQuestionPoolSize(state);

  return isQuestionPoolAllowed && questionPoolSize <= 0;
};

const isPreviousAttemptUrlExist = (state: any, progressStorage: any) => {
  return (
    shouldSubmitAllQuestions(state) &&
    isAnswersFromPreviousAttemptEnabled(state) &&
    progressStorage.url
  );
};

const getFirstIncorrectOrPendingQuestionAfterRandomize = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const isQuestionPoolAllowed = isQuestionPoolEnabled(getState());
  const isRandomizeEnabled = isQuestionRandomizeEnabled(getState());
  const isGuidedReattempt = hasGuidedReattempt(getState());

  if (isGuidedReattempt && isQuestionPoolAllowed && isRandomizeEnabled) {
    const questionToNavigate = getFirstIncorrectQuestionPreviousAttemptFromPool(getState());
    let urlToNavigate = INDEX_PATH;
    if (questionToNavigate) {
      urlToNavigate = getQuestionUrl(questionToNavigate.sectionId, questionToNavigate.id);
    }
    if (isAllQuestionsOnOnePage(getState())) {
      await dispatch(navigationActions.updateElementIdInViewPort(questionToNavigate.id));
    }
    await eventEmitter.emit(events.COURSE_URL_UPDATED, urlToNavigate);
  }
};

export const isCustomLrsUrlEmpty = async (state: any) => {
  const xapiSettings = getXapiSettings(state);
  return xapiSettings.selectedLrs !== defaultLrsName && xapiSettings.lrs.uri === '';
};

const isReviewOrPreviewMode = (state: any) => {
  return isPreviewMode(state) || isReviewMode(state);
};

export const launch = (isReAttempt = false): ThunkResult<Promise<string>> => async (
  dispatch,
  getState
) => {
  try {
    if (isCourseAccessLimited(getState())) {
      dispatch(onCourseLaunched());
      dispatch(restoreAccessibilitySettings());
      return INDEX_PATH;
    }

    if (isReviewOrPreviewMode(getState())) {
      return dispatch(loadPreviewMode());
    }

    await dispatch(loadScormMode(isReAttempt));
    const isProgressFetched = await progressStorage.restoreProgress();
    if (isReAttempt !== true) {
      await dispatch(processCourseChecksum());
    }

    const restoreStatus = await dispatch(restoreProgress(isProgressFetched));

    let attemptId = getAttemptId(getState());
    if (!attemptId) {
      attemptId = guidWithHyphens();
    }

    // TODO: events
    dispatch(onCourseLaunched(attemptId));

    const courseEmpty = await isCourseEmpty(getState());

    if (!restoreStatus && !courseEmpty) {
      await eventEmitter.emit(events.COURSE_ATTEMPT_STARTED, getState());
    }

    dispatch(loadPreviouslyAnsweredQuestions(restoreStatus));

    const customLrsUrlEmpty = await isCustomLrsUrlEmpty(getState());

    if (customLrsUrlEmpty) {
      eventEmitter.emit(events.APP_LRS_MISCONFIGURATION_ERROR);
      return INDEX_PATH;
    }

    await dispatch(doInitializeXapi(courseEmpty));

    if (hasBeenContinued(getState()) && !courseEmpty) {
      if (!hasBeenStarted(getState())) {
        await dispatch(onCourseStarted());
      }
      return progressStorage.url || INDEX_PATH;
    }

    await dispatch(onCourseStarted());

    if (isPreviousAttemptUrlExist(getState(), progressStorage)) {
      await dispatch(getFirstIncorrectOrPendingQuestionAfterRandomize());
      return progressStorage.url;
    }

    return INDEX_PATH;
  } catch (e) {
    dispatch({ type: ActionTypes.COURSE_LAUNCH_FAILED, reason: e });
    eventEmitter.emit(events.COURSE_LAUNCH_FAILED);
    return INDEX_PATH;
  }
};

export const doInitializeXapi = (courseEmpty: boolean): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  if (shouldInitializeXapi(getState()) && !courseEmpty) {
    await dispatch(initializeXapi());
    await dispatch(subscribeXapi());
  }
};

export const cleanup = (isLogout: boolean): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  dispatch({
    type: ActionTypes.COURSE_CLEANUP
  });

  dispatch(courseTimerActions.resetTimer());
  localStorage.setItem(getTimerProgressKey(getState()), '0');

  const sections = getSections(getState());
  sections.forEach(section => {
    dispatch(sectionActions.cleanup(section.id));
  });
  const questions = getQuestions(getState());
  if (
    shouldSubmitAllQuestions(getState()) &&
    isAnswersFromPreviousAttemptEnabled(getState()) &&
    !isLogout
  ) {
    questions.forEach(question => {
      dispatch(questionActions.cleanupKeepAnswers(question.id));
    });
  } else {
    questions.forEach(question => {
      dispatch(questionActions.cleanup(question.id));
    });
  }

  if (isXapiInitialized(getState())) {
    await dispatch(unsubscribeXapi());
  }
};

export const finished = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.COURSE_FINISHED
  });
  eventEmitter.emit(events.COURSE_FINISHED, getCourse(getState()));
};

export const finalized = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.COURSE_FINALIZED
  });
  eventEmitter.emit(events.COURSE_FINALIZED, getCourse(getState()));
};

export const startNewAttempt = (): ThunkResult<Promise<string>> => async (dispatch, getState) => {
  const currentAttemptCount = getCourseAttempt(getState());
  const isSubmitAllAtOnce = shouldSubmitAllQuestions(getState());

  let urlToNavigate = null;
  if (isSubmitAllAtOnce && isAnswersFromPreviousAttemptEnabled(getState())) {
    const questionToNavigate = getFirstIncorrectOrPendingQuestion(getState());
    if (questionToNavigate) {
      urlToNavigate = getQuestionUrl(questionToNavigate.sectionId, questionToNavigate.id);
      if (isAllQuestionsOnOnePage(getState())) {
        await dispatch(navigationActions.updateElementIdInViewPort(questionToNavigate.id));
      }
    }
  }

  await dispatch(cleanup(false));
  await progressStorage.removeProgress(false, urlToNavigate);
  if (isSubmitAllAtOnce) {
    await dispatch(courseAttemptedSubmitAtOnce());
    await dispatch(setCourseAttempt(currentAttemptCount));
  }

  if (isQuestionRandomizeEnabled(getState())) {
    let inProgressQuestionPool = progressStorage.getQuestionPool();
    if (inProgressQuestionPool && inProgressQuestionPool.length > 0) {
      inProgressQuestionPool = shuffle(inProgressQuestionPool);
      await eventEmitter.emit(events.COURSE_QUESTION_POOL_SELECTED, {
        questionPoolIds: inProgressQuestionPool,
        state: getState()
      });
    }
  }
  return dispatch(launch(true));
};

export const startOver = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await dispatch(cleanup(false));
  await progressStorage.removeProgress(true);
  await dispatch(launch());
};

export const certificateDownloaded = (
  isCertificateDownloaded: boolean
): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.CERTIFICATE_DOWNLOADED,
    payload: { isCertificateDownloaded }
  });
};

export const evaluate = (data: { score: number; response: any }) => () => {
  const { score, response } = data;
  eventEmitter.emit(events.COURSE_EVALUATED, {
    score,
    response
  });
};

export const courseTimeOutEmitter = (questionCollection: any[]): ThunkResult => async (
  dispatch,
  getState
) => {
  let statementsData = await Promise.all(
    questionCollection.map(async ({ id: questionId }) => {
      await dispatch(questionActions.pureAnswer(questionId));
      return getStatementsData(getState(), questionId);
    })
  );

  if (!isScoringOfContentPagesAllowed(getState())) {
    statementsData = filterStatementsInformationContent(statementsData);
  }

  eventEmitter.emit(events.COURSE_SUBMIT, { statementsData, state: getState() });
};
