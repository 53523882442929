export enum ActionTypes {
  URLS_LOADED = 'URLS_LOADED',
  NAVIGATED_INSIDE_THE_APP = 'NAVIGATED_INSIDE_THE_APP',
  POOL_URL_LOADED = 'POOL_URL_LOADED',
  ELEMENT_ID_IN_VIEWPORT_UPDATED = 'ELEMENT_ID_IN_VIEWPORT_UPDATED'
}

type UrlsLoaded = {
  type: ActionTypes.URLS_LOADED;
  payload: {
    sections: any;
    isShowContentPagesEnabled: boolean;
  };
};

type NavigatedInsideApp = {
  type: ActionTypes.NAVIGATED_INSIDE_THE_APP;
  payload: {
    prevUrl: string;
    currentUrl: string;
  };
};

type PoolUrlLoaded = {
  type: ActionTypes.POOL_URL_LOADED;
  payload: {
    [poolQuestions: string]: any;
  };
};

type elementIdInViewPortUpdated = {
  type: ActionTypes.ELEMENT_ID_IN_VIEWPORT_UPDATED;
  payload: {
    elementIdInViewport: string;
  };
};

export type NavigationActionTypes =
  | UrlsLoaded
  | NavigatedInsideApp
  | elementIdInViewPortUpdated
  | PoolUrlLoaded;
