import styled, { css } from 'styled-components';
import Text from 'components/common/Text';
import { BUTTON, FONT_SIZE, SIZES } from 'constants/components';
import Icon from 'components/common/Icon';
import { transition } from 'components/mixins/interactivity';
import buttonDefaultStyle from 'components/mixins/normalize';
import { MEDIA_LARGE } from 'constants/screenResolutions';
import { transparentize } from 'utils/color';
import { Layout, Size } from './Button';
import { fontSizeAdjusted } from 'components/mixins/typography';

type TextElementProps = {
  layout: keyof Layout;
};
export const TextElement = styled(Text)<TextElementProps>`
  ${props =>
    props.layout === BUTTON.LAYOUT.SIMPLE
      ? css`
          color: ${props.theme.colors.textColor};
          text-decoration: underline;
          font-weight: normal;
          opacity: ${props.theme.themeUtils.getOpacity(true, 0.7)};
        `
      : css`
          color: ${props.theme.colors.buttonTextColor};
          font-weight: 600;
          ${transition('padding-right')};
          && {
            ${fontSizeAdjusted(FONT_SIZE.INNER_PAGE.SYSTEM.BUTTON)};
          }
        `}
`;

export const IconElement = styled(Icon)`
  visibility: hidden;
  position: absolute;
  opacity: 0;
  top: 50%;
  transform: translate(0, -250%);
  right: 30px;
  transition: transform 0.1s ease-out, opacity 0.2s ease-out;
  transition-delay: 0.1s;
`;
type ButtonElementProps = {
  tabIndex: number;
  'data-test': string;
  textTransform: string;
  suffixIcon: string;
  isLoading: boolean;
  layout: keyof Layout;
  size: keyof Size;
  inverted: boolean;
  disabled: boolean;
  type: string;
};
export const ButtonElement = styled.button.attrs((props: ButtonElementProps) => ({
  tabIndex: props.tabIndex,
  disabled: props.disabled,
  'data-test': props['data-test'],
  type: props.type || BUTTON.TYPE.SUBMIT
}))<ButtonElementProps>`
  ${buttonDefaultStyle};
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.1s ease-out, focus 0s;
  align-items: center;
  text-transform: ${props => (props.textTransform ? props.textTransform : 'none')};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}

  ${props =>
    props.suffixIcon &&
    css`
      @media (min-width: ${MEDIA_LARGE}px) {
        &:hover ${TextElement}, &:focus ${TextElement} {
          padding-right: 20px;
        }

        &:hover ${IconElement}, &:focus ${IconElement} {
          visibility: visible;
          opacity: 1;
          transform: translate(0, -50%);
        }
      }
    `};

  ${props =>
    props.isLoading &&
    css`
      pointer-events: none;

      ${TextElement}, ${IconElement} {
        visibility: hidden;
      }
    `};

  ${props =>
    props.layout === BUTTON.LAYOUT.PRIMARY &&
    css`
      background-color: ${props.theme.colors.ctaButtonColor};

      &:hover,
      &:focus {
        background-color: ${() =>
          transparentize(props.theme.colors.ctaButtonColor, 0.85, { solidColor: true })};
      }

      &:focus {
        outline: 1px solid black;
      }

      &:focus:not(:focus-visible) {
        outline: none;
      }

      &:disabled {
        opacity: 0.5;
      }

      ${props.isLoading &&
        css`
          background-color: ${() => transparentize(props.theme.colors.ctaButtonColor, 0.05)};

          &:hover,
          &:focus {
            background-color: ${() => transparentize(props.theme.colors.ctaButtonColor, 0.05)};
          }
        `};
    `};

  ${props =>
    props.layout === BUTTON.LAYOUT.SECONDARY &&
    css`
      border: 2px solid
        ${props.inverted
          ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.5)
          : transparentize(props.theme.colors.textColor, 0.5)};
      background-color: transparent;

      &:hover,
      &:focus {
        background-clip: padding-box;
        border: 2px solid
          ${props.inverted
            ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.1)
            : transparentize(props.theme.colors.textColor, 0.1)};
      }

      ${TextElement} {
        color: ${props.inverted
          ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.7)
          : transparentize(props.theme.colors.textColor, 0.7)};
      }

      &:hover,
      &:focus {
        &,
        &:active {
          background-color: ${props.inverted
            ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.1)
            : transparentize(props.theme.colors.textColor, 0.1)};
        }

        ${TextElement},
        ${IconElement} {
          color: ${props.inverted
            ? props.theme.colors.coverBgOverlayTextColor
            : props.theme.colors.textColor};
        }
      }

      &:active ${TextElement}, &:active ${IconElement} {
        color: ${props.inverted
          ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.6)
          : transparentize(props.theme.colors.textColor, 0.6)};
      }

      &:focus {
        background-color: ${props.inverted
          ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.1)
          : transparentize(props.theme.colors.textColor, 0.1)};
        outline: 1px solid black;
      }

      &:focus:not(:focus-visible) {
        background-color: ${props.inverted
          ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.1)
          : transparentize(props.theme.colors.textColor, 0.1)};
        outline: none;
      }

      &:disabled {
        opacity: 0.5;
      }
    `};

  ${props =>
    props.layout === BUTTON.LAYOUT.SIMPLE &&
    css`
      background-color: none;
      border-radius: 0;
      padding: 0;

      &:focus {
        outline: 1px solid black;
      }

      &:focus:not(:focus-visible) {
        outline: none;
      }
    `};

  ${props =>
    props.size === SIZES.MEDIUM &&
    css`
      height: 50px;
      padding: 0 40px;
      line-height: 14px;
    `};

  ${props =>
    props.size === SIZES.SMALL &&
    css`
      height: 30px;
      padding: 0 23px;
      line-height: 10px;
    `};
`;

export const ButtonLinkElement = styled(ButtonElement.withComponent('a'))`
  text-decoration: none !important;

  ${TextElement} {
    margin-top: 1px;
  }
`;
