import eventEmitter, { events } from 'core/events/eventEmitter';

import { ActionTypes } from './types';
import { ThunkResult } from '../types';

export const navigatedToUrl = (
  prevUrl: string,
  currentUrl: string
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await eventEmitter.emit(events.APP_NAVIGATED, { url: currentUrl, state: getState() });
  dispatch({ type: ActionTypes.NAVIGATED_INSIDE_THE_APP, payload: { prevUrl, currentUrl } });
};

export const goToUrl = (url: string): ThunkResult => () => {
  window.open(url, '_self');
};

export const poolUrlReset = (questions: any): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.POOL_URL_LOADED, payload: questions });
};

export const updateElementIdInViewPort = (
  elementIdInViewport: string
): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({ type: ActionTypes.ELEMENT_ID_IN_VIEWPORT_UPDATED, payload: { elementIdInViewport } });
};

export const loadUrls = (
  sections: any,
  isShowContentPagesEnabled: boolean
): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.URLS_LOADED, payload: { sections, isShowContentPagesEnabled } });
};
