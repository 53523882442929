import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import SwitchButton from 'components/common/SwitchButton';
import { getTreeOfContentVisibility } from 'store/treeOfContent/selectors';
import * as actions from 'store/popup/actions';
import { localize } from 'core/localization';
import { POPUP_ID_IMAGE_PREVIEW } from 'constants/popups';
import { KEYS } from 'constants/common';
import { RootAppState } from 'store/types';
import { addAriaLive } from '../../../utils/dom';
import {
  ListItemsBlock,
  ListItem,
  ZoomIconButton,
  ZoomIconBg,
  ZoomIcon
} from './MultipleChoiceItems.styled';
import PreviewGallery from '../../popups/previewGallery/PreviewGallery';
import { SWITCH_BUTTON_LAYOUT } from '../../../constants/components';

const INPUT_TYPE = {
  multiple: {
    checked: 'checked',
    unchecked: 'unchecked'
  },
  single: {
    selected: 'selected',
    notSelected: 'not selected'
  }
};

export type MultipleChoiceItemsProps = {
  items: any[];
  id: string;
  isMultiple: boolean;
  imageQuestion: boolean;
  selectItem(id: string, itemId?: string, isMultiple?: boolean): void;
  actions: { [key: string]: any };
  isTreeOfContentExpanded: boolean;
  ariaDisabled: boolean;
  tabIndex: number;
};

const getMultipleChoiceQuestion: any = () => document.getElementById('multipleChoiceQuestion');
export class MultipleChoiceItems extends React.Component<MultipleChoiceItemsProps, {}> {
  state = {
    items: this.props.items,
    isOpened: false,
    imageUrl: []
  };

  static defaultProps = {
    imageQuestion: false,
    ariaDisabled: false
  };

  componentDidMount() {
    const obj = {
      id: 'multipleChoiceQuestion',
      attributes: [
        { key: 'aria-live', value: 'assertive' },
        { key: 'data-test', value: 'multiple-choice-question-aria-live' }
      ]
    };
    addAriaLive(obj);
  }

  getAriaLiveAnswer = (selected: boolean, isMultiple: boolean) => {
    if (!selected) {
      return isMultiple ? INPUT_TYPE.multiple.unchecked : INPUT_TYPE.single.notSelected;
    }

    return isMultiple ? INPUT_TYPE.multiple.checked : INPUT_TYPE.single.selected;
  };

  selectItem = (item: any) => {
    const { id, isMultiple, selectItem } = this.props;
    const { items } = this.state;

    if (isMultiple) {
      items.forEach(a => {
        if (item.id === a.id) {
          a.selected = !a.selected;
        }
      });
    } else {
      items.forEach(a => {
        if (item.id === a.id) {
          a.selected = true;
        } else {
          a.selected = false;
        }
      });
    }
    if (typeof selectItem === 'function') {
      selectItem(id, item.id, isMultiple);
    }
    const choiceQuestion = getMultipleChoiceQuestion();
    if (choiceQuestion && choiceQuestion.children.length) {
      const { text, selected } = item;
      choiceQuestion.children[0].innerHTML = `${text} ${this.getAriaLiveAnswer(
        selected,
        isMultiple
      )}`;
    }
    this.setState({
      items
    });
  };

  static getDerivedStateFromProps(
    nextProps: MultipleChoiceItemsProps,
    prevState: MultipleChoiceItemsProps
  ) {
    if (prevState.id !== nextProps.id && prevState.items !== nextProps.items) {
      return {
        items: nextProps.items
      };
    }
    return null;
  }

  showPopup = (imageUrl: string) => {
    this.props.actions.openPopup({
      popupId: POPUP_ID_IMAGE_PREVIEW,
      popupAriaLabelKey: '[aria label gallery popup]',
      component: PreviewGallery,
      imageUrls: [imageUrl],
      disableBottomLine: true
    });
  };

  handleOnClick = (ariaDisabled: boolean, altAnswerImage: string, item: any) => {
    if (!ariaDisabled) {
      if (item.image) {
        item.text = altAnswerImage;
      }
      this.selectItem(item);
    }
  };

  handleOnKeyDown = (event: any, ariaDisabled: boolean, item: any) => {
    if (
      (event.key?.toLowerCase() === KEYS.SPACEBAR.toLowerCase() ||
        event.key?.toLowerCase() === KEYS.ENTER.toLowerCase()) &&
      !ariaDisabled
    ) {
      this.selectItem(item);
    }
  };

  render() {
    const {
      isMultiple,
      imageQuestion,
      isTreeOfContentExpanded,
      ariaDisabled,
      tabIndex
    } = this.props;
    const { items } = this.state;
    return (
      <ListItemsBlock
        isImageQuestion={imageQuestion}
        isTreeOfContentExpanded={isTreeOfContentExpanded}
      >
        {items.map((item, index) => {
          const altAnswerImage = `${localize('[alt answer image]')} ${index + 1}`;
          return (
            <ListItem
              selected={item.selected}
              key={`list-item-${index}`}
              isImageQuestion={imageQuestion}
              isTreeOfContentExpanded={isTreeOfContentExpanded}
            >
              <SwitchButton
                multiple={isMultiple}
                selected={item.selected}
                text={item.text}
                imageUrl={item.image}
                ariaDisabled={ariaDisabled}
                layout={
                  imageQuestion ? SWITCH_BUTTON_LAYOUT.IMAGE_ANSWER : SWITCH_BUTTON_LAYOUT.WITH_TEXT
                }
                altAnswerImage={altAnswerImage}
                tabIndex={tabIndex}
                onClick={() => this.handleOnClick(ariaDisabled, altAnswerImage, item)}
                onKeyDown={(event: any) => this.handleOnKeyDown(event, ariaDisabled, item)}
              />
              <ZoomIconButton
                className="zoom-button"
                ariaLabel={localize('[aria label zoom button]')}
                onClick={() => this.showPopup(item.image)}
                tabIndex={0}
                isShown={!!item.image}
              >
                <ZoomIconBg size={41} name="zoom-bg" />
                <ZoomIcon size={41} name="zoom" />
              </ZoomIconButton>
            </ListItem>
          );
        })}
      </ListItemsBlock>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    isTreeOfContentExpanded: getTreeOfContentVisibility(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceItems);
