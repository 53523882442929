import eventEmitter, { events } from 'core/events/eventEmitter';
import progressStorage from 'core/progressStorage/progressStorage';
import { ThunkResult } from '../types';
import { AccessibilityState, ActionTypes } from './types';

export const updateAccessibilitySettings = (
  accessibility: AccessibilityState
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.UPDATE_ACCESSIBILITY_SETTINGS,
    payload: {
      accessibility
    }
  });

  await eventEmitter.emit(events.UPDATE_ACCESSIBILITY_SETTINGS, {
    accessibility,
    state: getState()
  });
};

export const restoreAccessibilitySettings = (): ThunkResult<Promise<void>> => async dispatch => {
  const accessibility = await progressStorage.restoreAccessibilitySettings();

  dispatch({
    type: ActionTypes.RESTORE_ACCESSIBILITY_SETTINGS,
    payload: {
      accessibility
    }
  });
};
