import * as React from 'react';
import isEmpty from 'lodash.isempty';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cloneDeep from 'lodash.clonedeep';
import { localize } from 'core/localization';
import NumericList from 'components/common/NumericList';
import { NO_SELECTED_ID } from 'constants/common';
import CircleLoader from 'components/common/CircleLoader';
import {
  DroppableListStyle,
  DroppableItemStyle,
  DroppableItemWrapper,
  ArrowIcon,
  ImageWrapper
} from './DragDrop.styled';

const DROPPABLE_WIDTH = 44;
const UNPLACED_ANSWER_OPTIONS_INDEX = 0;

type DragDropProps = {
  id: string;
  listsOfItems: any[];
  moveItem(arg1: any, arg2: any, arg3: any, arg4: boolean, arg5: any, arg6?: any): void;
  isMultipleList: boolean;
  actions?: { [key: string]: any };
  imageUrl?: string;
  getDragActions?(): void;
  background?: string;
  setPositionDependingImageScale?(): void;
  getPositionDependingImageScale?(arg1: any, arg2: any, arg3: any): void;
  handleImageLoaded?(arg1: any, arg2: any): void;
  isImageLoaded?: boolean;
  allowSubmit: boolean;
  questionActions?: { [key: string]: any };
  isImageNotFound?: boolean;
  dropspots?: any[];
  retries?: number;
  isAnswered: boolean;
};

type DragDropState = {
  listsOfItems: any;
  isPlaceholderVisible: boolean;
  numericListHeights: any[];
  droppableWidth: any;
  droppableWidthInvisible: any;
  isAnswered: boolean;
  retries: number | undefined;
};
export class DragDrop extends React.Component<DragDropProps, DragDropState> {
  customRefs: any;
  imageRefs: any;

  constructor(props: DragDropProps) {
    super(props);
    this.customRefs = {};
    this.imageRefs = {};
    this.state = {
      listsOfItems: props.listsOfItems,
      isPlaceholderVisible: true,
      numericListHeights: [],
      droppableWidth: DROPPABLE_WIDTH,
      droppableWidthInvisible: DROPPABLE_WIDTH,
      isAnswered: props.isAnswered || false,
      retries: props.retries || 0
    };

    if (!props.isMultipleList) {
      props.moveItem(
        this.state.listsOfItems[0].items,
        props.listsOfItems[0].id,
        props.actions,
        props.allowSubmit,
        props.questionActions,
        true
      );
    } else {
      props.moveItem(
        this.state.listsOfItems,
        props.id,
        props.actions,
        props.allowSubmit,
        props.questionActions,
        true
      );
    }
  }

  static getDerivedStateFromProps(nextProps: DragDropProps, prevState: DragDropState) {
    const { listsOfItems } = nextProps;
    const { listsOfItems: listsOfItemsState } = prevState;
    const derivedData: any = {};
    if (nextProps.retries !== prevState.retries) {
      derivedData.retries = nextProps.retries;
    }
    if (nextProps.isAnswered !== prevState.isAnswered) {
      derivedData.isAnswered = nextProps.isAnswered;
    }
    if (
      listsOfItems.length &&
      listsOfItemsState.length &&
      listsOfItems[UNPLACED_ANSWER_OPTIONS_INDEX].items.length !==
        listsOfItemsState[UNPLACED_ANSWER_OPTIONS_INDEX].items.length
    ) {
      derivedData.listsOfItems = DragDrop.getListOfItemsPosition(listsOfItems, listsOfItemsState);
    }

    return !isEmpty(derivedData) ? derivedData : null;
  }

  move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
    const { listsOfItems } = this.state;
    const result: any[] = Array.from(listsOfItems);

    const [removed] = source.splice(droppableSource.index, 1);

    destination.splice(droppableDestination.index, 0, removed);

    listsOfItems.forEach((element: any, index: any) => {
      if (droppableSource.droppableId === element.id) {
        result[index].items = source;
      }

      if (droppableDestination.droppableId === element.id) {
        result[index].items = destination;
      }

      if (result[index].items.length && result[index].id !== NO_SELECTED_ID) {
        result[index].isDropDisabled = true;
      }
    });

    return result;
  };

  reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getItemStyle = (draggableStyle: any) => ({
    // styles we need to apply on draggables
    ...draggableStyle
  });

  getList = (listId: any) => {
    const { listsOfItems } = this.state;
    const list = listsOfItems.find((element: any) => element.id === listId);
    return list.items;
  };

  setDisableDroppableArea = (droppableId: any) => {
    const { listsOfItems } = this.state;
    const results: any[] = Array.from(listsOfItems);
    listsOfItems.forEach((element: any, index: any) => {
      if (results[index].id === droppableId && droppableId !== NO_SELECTED_ID) {
        results[index].isDropDisabled = true;
      }
    });
    this.setState({
      listsOfItems: results
    });
  };

  reorderElementsPosition = (result: any) => {
    const { listsOfItems } = this.state;
    const { destination, source } = result;
    listsOfItems.forEach((element: any) => {
      if (destination.droppableId === element.id) {
        const reorderedItems = this.reorder(element.items, source.index, destination.index);
        element.items = reorderedItems;
        this.props.moveItem(
          reorderedItems,
          this.props.id,
          this.props.actions,
          this.props.allowSubmit,
          this.props.questionActions
        );
      }
    });
    this.setState({
      listsOfItems,
      isPlaceholderVisible: true
    });
  };

  moveElementsBetweenLists = (source: any, destination: any) => {
    const results = this.move(
      this.getList(source.droppableId),
      this.getList(destination.droppableId),
      source,
      destination
    );
    this.setState(
      {
        listsOfItems: results
      },
      () => {
        this.props.moveItem(
          results,
          this.props.id,
          this.props.actions,
          this.props.allowSubmit,
          this.props.questionActions
        );
      }
    );
  };

  onDragEnd = (result: any) => {
    // dropped outside the list
    const { isMultipleList } = this.props;
    const { destination, source } = result;
    this.setState({
      droppableWidth: DROPPABLE_WIDTH
    });
    if (!destination) {
      if (isMultipleList) {
        this.setDisableDroppableArea(source.droppableId);
      }
      return;
    }
    if (source.droppableId === destination.droppableId) {
      if (!isMultipleList) {
        this.reorderElementsPosition(result);
      }
    } else {
      this.moveElementsBetweenLists(source, destination);
    }
  };

  onDragUpdate = (result: any) => {
    const { destination, source } = result;

    this.setState({
      isPlaceholderVisible: !destination || destination.index === source.index
    });

    if (!this.props.isMultipleList) {
      this.setNumericListHeights(destination, source);
    }
  };

  onDragStart = (result: any) => {
    const { source } = result;
    const resultArr: any[] = Array.from(this.state.listsOfItems);

    resultArr.forEach((list: any, index: any) => {
      if (list.id === source.droppableId) {
        resultArr[index].isDropDisabled = false;
      }
    });

    this.setState({
      listsOfItems: resultArr,
      droppableWidth: this.customRefs[result.draggableId].offsetWidth
    });
  };

  setDraggableWidth = (itemId: any) => {
    if (this.customRefs[itemId]) {
      this.setState({
        droppableWidthInvisible: this.customRefs[itemId].offsetWidth
      });
    }
  };

  setNumericListHeights(destination: any, source?: any) {
    const { listsOfItems } = this.state;
    const draggableList = listsOfItems[0].items.slice();
    const numericListHeights: any[] = [];
    const reorderedItems = !destination
      ? draggableList
      : this.reorder(draggableList, source.index, destination.index);

    reorderedItems.forEach((item: any) => {
      numericListHeights.push(this.customRefs[item.id].offsetHeight + 10);
    });
    this.setState({
      numericListHeights
    });
  }

  setRef(itemId: any, ref: any, refsArray: any) {
    refsArray[itemId] = ref;
  }

  resetListOfItems = () => {
    const { getPositionDependingImageScale } = this.props;
    if (getPositionDependingImageScale) {
      this.setState({
        listsOfItems: getPositionDependingImageScale(
          this.imageRefs.background,
          this.getDropspotsPosition(),
          this.props.listsOfItems
        )
      });
    }
  };

  getDropspotsPosition = () => {
    const { dropspots = [] } = this.props;
    return dropspots.reduce((dropspotsPosition, dropspot) => {
      dropspotsPosition[dropspot.id] = { y: dropspot.y, x: dropspot.x };
      return dropspotsPosition;
    }, []);
  };

  static getListOfItemsPosition = (listsOfItems: any, listsOfItemsState: any) => {
    listsOfItemsState.forEach((e: any, i: number) => {
      if (e.hasOwnProperty('position')) {
        listsOfItems[i].position = e.position;
      }
    });

    return listsOfItems;
  };

  componentDidUpdate(previousProps: DragDropProps, previousState: DragDropState) {
    const { listsOfItems } = previousProps;
    const { listsOfItems: listsOfItemsState } = previousState;
    if (!this.state.numericListHeights.length && !this.props.isMultipleList) {
      this.setNumericListHeights(false);
    }
    if (previousState.retries !== this.state.retries && this.props.isMultipleList) {
      this.resetListOfItems();
    }
    if (previousState.isAnswered !== this.state.isAnswered) {
      this.setState({
        listsOfItems: DragDrop.getListOfItemsPosition(listsOfItems, listsOfItemsState)
      });
    }
  }

  render() {
    const {
      listsOfItems,
      isPlaceholderVisible,
      numericListHeights,
      droppableWidth,
      droppableWidthInvisible
    } = this.state;
    const {
      isMultipleList,
      background = '',
      isImageLoaded = false,
      isImageNotFound = false
    } = this.props;
    const listsOfItemsArr = Array.from(cloneDeep(listsOfItems));
    const nonSelected = listsOfItemsArr.shift();
    const props = {
      nonSelected,
      isMultipleList,
      droppableWidth,
      droppableWidthInvisible,
      isImageLoaded,
      isImageNotFound,
      numericListHeights,
      isPlaceholderVisible
    };
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragUpdate={this.onDragUpdate}
        onDragStart={this.onDragStart}
      >
        <div data-test="answer-options">{this.getDroppableElement(nonSelected, props)}</div>
        {isMultipleList && (
          <ImageWrapper isImageLoaded={isImageLoaded} isImageNotFound={isImageNotFound}>
            {!isImageNotFound &&
              listsOfItemsArr.map(listItem => this.getDroppableElement(listItem, props))}
            {background && !isImageLoaded && <CircleLoader />}
            <img
              src={background}
              ref={(ref: any) => this.setRef('background', ref, this.imageRefs)}
              onLoad={() =>
                this.props.handleImageLoaded &&
                this.props.handleImageLoaded(this.imageRefs.background, listsOfItems)
              }
              alt={localize('[drag and drop question image]')}
            />
          </ImageWrapper>
        )}
      </DragDropContext>
    );
  }

  getDroppableElement(listItem: any, props: any) {
    return (
      <Droppable
        isDropDisabled={listItem.isDropDisabled}
        key={listItem.id}
        droppableId={listItem.id}
        direction={props.isMultipleList ? 'horizontal' : 'vertical'}
      >
        {(listProvided: any) => (
          <DroppableItemWrapper
            isAreaEmpty={listItem.id === NO_SELECTED_ID && !listItem.items.length}
            isMultipleList={props.isMultipleList}
            position={listItem.position}
            isDropDisabled={listItem.isDropDisabled}
            width={props.droppableWidth}
            widthInvisible={props.droppableWidthInvisible}
            isImageLoaded={props.isImageLoaded}
          >
            {listItem.position && <ArrowIcon size={9} name="arrow-down" />}
            {!props.isMultipleList && <NumericList items={props.numericListHeights} />}
            <DroppableListStyle
              data-area-empty-text={localize('[drag and drop question all texts are placed]')}
              ref={listProvided.innerRef}
            >
              {listItem.items.map((item: any, index: any) => (
                <Draggable draggableId={item.id} index={index} key={item.id}>
                  {(provided, snapshot) => (
                    <DroppableItemStyle
                      onMouseEnter={() => this.setDraggableWidth(item.id)}
                      isMultipleList={props.isMultipleList}
                      isPlaceholderVisible={props.isPlaceholderVisible}
                      isDragging={snapshot.isDragging}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={this.getItemStyle(provided.draggableProps.style)}
                      tabIndex={0}
                      title={item.text}
                    >
                      <div
                        ref={ref => this.setRef(item.id, ref, this.customRefs)}
                        dangerouslySetInnerHTML={{ __html: item.text ? item.text : `&nbsp;` }}
                      />
                    </DroppableItemStyle>
                  )}
                </Draggable>
              ))}
            </DroppableListStyle>
            {listProvided.placeholder}
          </DroppableItemWrapper>
        )}
      </Droppable>
    );
  }
}

export default DragDrop;
