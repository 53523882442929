import { events } from 'core/events/eventEmitter';

export const defaultLrsName = 'default';

export const separators = {
  comma: '[,]',
  dot: '[.]'
};

export const lxpStatementsBaseUrl = 'api/lxp';

export const interactionTypes = {
  choice: 'choice',
  fillIn: 'fill-in',
  matching: 'matching',
  dragAndDrop: 'dragAndDrop',
  hotspot: 'hotspot',
  sequencing: 'sequencing',
  other: 'other'
};

export const activityTypes = {
  course: 'http://adlnet.gov/expapi/activities/course',
  objective: 'http://adlnet.gov/expapi/activities/objective',
  interaction: 'http://adlnet.gov/expapi/activities/cmi.interaction'
};

export const extensionKeys = {
  courseId: 'http://easygenerator/expapi/course/id',
  surveyMode: 'http://easygenerator/expapi/question/survey',
  questionType: 'http://easygenerator/expapi/question/type',
  imageUrl: 'http://easygenerator/expapi/question/imageUrl',
  content: 'http://easygenerator/expapi/question/content',
  answerTexts: 'http://easygenerator/expapi/question/answers'
};

export interface IEventsAgainstVerbs {
  started: string;
  completed: string;
}

export interface IHandlerMethodsForEvents {
  [key: string]: string;
}

export const eventsAgainstVerbs = {
  started: events.COURSE_STARTED,
  completed: events.COURSE_COMPLETED
};

export const handlerMethodsForEvents: IHandlerMethodsForEvents = {
  [events.COURSE_STARTED]: 'courseStarted',
  [events.COURSE_COMPLETED]: 'courseCompleted'
};

export const verbs = {
  launched: {
    id: 'http://adlnet.gov/expapi/verbs/launched',
    display: {
      'en-US': 'started'
    }
  },
  started: {
    id: 'http://adlnet.gov/expapi/verbs/started',
    display: {
      'en-US': 'started'
    }
  },
  stopped: {
    id: 'http://adlnet.gov/expapi/verbs/exited',
    display: {
      'en-US': 'stopped'
    }
  },
  passed: {
    id: 'http://adlnet.gov/expapi/verbs/passed',
    display: {
      'en-US': 'passed'
    }
  },
  failed: {
    id: 'http://adlnet.gov/expapi/verbs/failed',
    display: {
      'en-US': 'failed'
    }
  },
  experienced: {
    id: 'http://adlnet.gov/expapi/verbs/experienced',
    display: {
      'en-US': 'experienced'
    }
  },
  answered: {
    id: 'http://adlnet.gov/expapi/verbs/answered',
    display: {
      'en-US': 'answered'
    }
  },
  mastered: {
    id: 'http://adlnet.gov/expapi/verbs/mastered',
    display: {
      'en-US': 'mastered'
    }
  },
  progressed: {
    id: 'http://adlnet.gov/expapi/verbs/progressed',
    display: {
      'en-US': 'progressed'
    }
  },
  evaluated: {
    id: 'http://www.tincanapi.co.uk/verbs/evaluated',
    display: {
      'en-US': 'evaluated'
    }
  },
  completed: {
    id: 'http://adlnet.gov/expapi/verbs/completed',
    display: {
      'en-US': 'completed'
    }
  }
};
