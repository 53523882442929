import * as React from 'react';
import { isTouchScreenDevice } from 'utils/window';
import { withTheme } from 'styled-components';
import { Tooltip, TooltipProps } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import './Tooltip.css';
import { TooltipContentWrapper, TooltipContentArrow } from './Tooltip.styled';
import { FILL_IN_THE_BLANK } from 'constants/questionTypes';

interface TooltipElementProps extends TooltipProps {
  classNames?: string;
  children: React.ReactChild;
  tooltipTheme?: 'dark' | 'light' | 'transparent';
  templateTheme?: { [key: string]: any };
  questionType?: string;
}

const TooltipElement = ({ ...props }: TooltipElementProps) => {
  const newProps: TooltipElementProps = props;
  let modifiers;

  if (newProps.questionType === FILL_IN_THE_BLANK) {
    modifiers = {
      preventOverflow: { enabled: false },
      computeStyle: { gpuAcceleration: false }
    };
  } else {
    modifiers = {
      preventOverflow: { boundariesElement: 'viewport' },
      computeStyle: { gpuAcceleration: false }
    };
  }
  newProps.popperOptions.modifiers = modifiers;
  newProps.html = (
    <TooltipContentWrapper
      className={newProps.classNames}
      templateTheme={newProps.theme}
      onMouseUp={e => e.stopPropagation()}
    >
      {props.html}
      {props.arrow && <TooltipContentArrow templateTheme={newProps.theme} />}
    </TooltipContentWrapper>
  );
  newProps.arrow = false;
  return (
    <Tooltip {...newProps} theme={newProps.tooltipTheme}>
      {newProps.children}
    </Tooltip>
  );
};

TooltipElement.defaultProps = {
  popperOptions: {
    positionFixed: isTouchScreenDevice()
  },
  useContext: true,
  stickyDuration: 0,
  tooltipTheme: 'light',
  animateFill: false,
  arrow: false
};

export default withTheme(TooltipElement);
