export const DEFAULT_ALTERNATIVE_LOGO_URL = '//cdn.easygenerator.com/white-logo-v2.png';

export const DEFAULT_BACKGROUND = {
  header: {
    image: {
      url: '//cdn.easygenerator.com/images/14.jpg',
      option: 'fullscreen'
    },
    coverOverlay: {
      enabled: false,
      color: '#EC7111',
      opacity: 0.8
    }
  },
  body: {
    enabled: true,
    color: '#FFFFFF'
  }
};

export const CONTRAST_BACKGROUND = {
  ...DEFAULT_BACKGROUND,
  header: {
    ...DEFAULT_BACKGROUND.header,
    color: '#1C3049'
  }
};

export const DEFAULT_COLORS = {
  textColor: '#363E4E'
};

export const DEFAULT_FONTS = {
  mainFont: {
    fontFamily: 'Arial'
  },
  normal: {}
};

export const DEFAULT_SOMETHING_WENT_WRONG_TITLE_TEXT = 'Something went wrong';
export const DEFAULT_LOGO_TEXT = 'Logo';
export const DEFAULT_PLEASE_TRY_TY_REFRESH_THE_PAGE_TEXT = 'Please try to refresh the page';
