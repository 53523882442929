import initialState from 'store/initialState';
import { ActionTypes, AppActionTypes } from './types';

export default function appReducer(state = initialState.app, action: AppActionTypes) {
  switch (action.type) {
    case ActionTypes.APP_LOADED:
      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: true
      });
    case ActionTypes.APP_LOADING_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isLoadingFailed: true
      });
    case ActionTypes.APP_RESOLUTION_CHANGED:
      return Object.assign({}, state, {
        ...action.payload
      });
    case ActionTypes.APP_OFFLINE_OR_SERVER_ERROR:
      return Object.assign({}, state, {
        isOffline: true
      });
    case ActionTypes.APP_LRS_MISCONFIGURATION_ERROR:
      return Object.assign({}, state, {
        isLrsMisconfigurationError: true
      });
    case ActionTypes.APP_ONLINE:
      return Object.assign({}, state, {
        isOffline: false
      });
    default:
      return state;
  }
}
