import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  INDEX_PATH,
  QUESTION_PATH,
  RESULTS_PATH,
  SECTION_PATH,
  SECTIONS_PATH
} from 'constants/navigation';
import {
  getTimerEnabled
} from 'store/settings/selectors';
import { getTimerElapsed, getTimerStatus } from 'store/timer/selectors';
import { TRANSITION_TIME_DURATION_MS } from 'constants/common';
import { getChain, getPrevUrl } from 'store/navigation/selectors';
import * as actions from 'store/navigation/actions';
import { RootAppState } from 'store/types';
import scrollIntoView from 'scroll-into-view';
import { TimerStatus } from 'constants/timer';
import * as courseTimerActions from 'store/timer/actions';

type NavigationProps = {
  chain: any[];
  prevUrl: string;
  actions: { [key: string]: any };
  timerActions: { [key: string]: any };
  history: { [key: string]: any };
  match: { [key: string]: any };
  navigateToUrl?: any;
  checkHasPrevPage?: any;
  navigateToPrevPage?: any;
  navigateToNextPage?: any;
  navigateToIndex?: any;
  isNextPageResults?: any;
  isTimerEnabled: boolean;
  timerStatus: any;
  timeElapsed: number;
  scrollToId: any;
};

export function withNavigation<T>(WrappedComponent: React.ComponentType<T>): any {
  class Navigation extends React.Component<T & NavigationProps, any> {
    getNextPageUrl = () => {
      const { chain, match } = this.props;
      const { url } = match;
      let currentUrlIndex = chain.indexOf(url);
      if (currentUrlIndex === chain.length - 1) {
        return INDEX_PATH;
      }

      return chain[++currentUrlIndex];
    };

    checkHasPrevPage = () => this.props.prevUrl;

    navigateToPrevPage = () => {
      this.navigateToUrl(this.props.prevUrl);
    };

    navigateToNextPage = () => {
      this.navigateToUrl(this.getNextPageUrl());
    };

    navigateToIndex = () => {
      this.navigateToUrl(INDEX_PATH);
    };

    navigateToUrl = async (url: string, isReplace = false) => {
      if (this.props.history.location.pathname === url) {
        return;
      }

      if (url === SECTIONS_PATH || url === SECTION_PATH || url === QUESTION_PATH) {
        if (this.props.isTimerEnabled && (this.props.timerStatus === TimerStatus.NOT_STARTED || this.props.timerStatus === TimerStatus.RESET )) {
          this.props.timerActions.setTimerElapsed(this.props.timeElapsed);
          this.props.timerActions.startTimer();
        }
      }

      await this.props.actions.navigatedToUrl(this.props.history.location.pathname, url);
      if (isReplace) {
        this.props.history.replace(url);
      } else {
        this.props.history.push(url);
      }
    };

    isNextPageResults = () => this.getNextPageUrl() === RESULTS_PATH;
    isPreviousPageResults = () => this.props.prevUrl === RESULTS_PATH;

    updateElementIdInViewPort = async (id: string) => {
      await this.props.actions.updateElementIdInViewPort(id);
    };

    scrollToId = async (id: string, offset?: number) => {
      if (id === undefined) {
        await this.navigateToUrl(RESULTS_PATH);
      }
      await this.updateElementIdInViewPort(id);
      await this.scrollIntoViewElement(id, offset);
    };

    scrollIntoViewElement = async (elementId: any, offset = 100) => {
      const scrollElement: HTMLElement | null = document.getElementById(elementId);

      if (scrollElement) {
        scrollIntoView(scrollElement, {
          time: TRANSITION_TIME_DURATION_MS,
          align: { top: 0, topOffset: offset }
        });
      }
    };

    render() {
      const combinedProps = {
        ...this.props,
        navigateToUrl: this.navigateToUrl,
        checkHasPrevPage: this.checkHasPrevPage,
        navigateToPrevPage: this.navigateToPrevPage,
        navigateToNextPage: this.navigateToNextPage,
        navigateToIndex: this.navigateToIndex,
        isNextPageResults: this.isNextPageResults,
        isPreviousPageResults: this.isPreviousPageResults,
        scrollToId: this.scrollToId,
        updateElementIdInViewPort: this.updateElementIdInViewPort
      };
      return <WrappedComponent {...combinedProps} />;
    }
  }

  function mapStateToProps(state: RootAppState) {
    return {
      state,
      chain: getChain(state),
      isTimerEnabled: getTimerEnabled(state),
      timerStatus: getTimerStatus(state),
      timeElapsed: getTimerElapsed(state),
      prevUrl: getPrevUrl(state)
    };
  }

  function mapDispatchToProps(dispatch: Dispatch) {
    return {
      actions: bindActionCreators(actions, dispatch),
      timerActions: bindActionCreators(courseTimerActions, dispatch)
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(Navigation as any);
}

export default withNavigation;
