import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import app from './app/reducer';
import settings from './settings/reducer';
import navigation from './navigation/reducer';
import course from './course/reducer';
import sections from './sections/reducer';
import contents from './contents/reducer';
import modules from './modules/reducer';
import treeOfContent from './treeOfContent/reducer';
import popup from './popup/reducer';
import loader from './loader/reducer';
import questions from './questions/reducer';
import multipleChoice from './questions/multipleChoice/responseReducer';
import StatementChoice from './questions/statementChoice/responseReducer';
import openQuestion from './questions/openQuestion/responseReducer';
import rankingText from './questions/rankingText/responseReducer';
import scenario from './questions/scenario/responseReducer';
import fillInTheBlank from './questions/fillInTheBlank/responseReducer';
import dragAndDropText from './questions/dragAndDropText/responseReducer';
import hotspot from './questions/hotspot/responseReducer';
import user from './user/reducer';
import textMatching from './questions/textMatching/responseReducer';
import resource from './resource/reducer';
import checklist from './resource/checklist/reducer';
import timer from './timer/reducer';
import accessibility from './accessibility/reducer';

const rootReducer = combineReducers({
  accessibility,
  app,
  course,
  modules,
  settings,
  router: routerReducer,
  questions,
  sections,
  contents,
  navigation,
  treeOfContent,
  popup,
  loader,
  user,
  resource,
  checklist,
  timer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const questionResponseReducers = [
  multipleChoice,
  StatementChoice,
  dragAndDropText,
  openQuestion,
  rankingText,
  scenario,
  fillInTheBlank,
  textMatching,
  hotspot
];
