import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IS_NO_ACCESS, IS_NOT_AUTHORIZED } from 'constants/typeAccess';
import { LOGIN_PATH, QUESTION_PATH, SECTION_PATH, SECTIONS_PATH } from 'constants/navigation';
import { MATERIAL_TYPES } from 'constants/common';
import { RootAppState } from 'store/types';
import {
  isCourseAccessLimited,
  isProgressRestoreFetched,
  isProgressRestoreFailed,
  hasBeenStarted,
  isCourseLaunchFailed
} from 'store/course/selectors';
import { isLoaderVisibility } from 'store/loader/selectors';
import { isReviewMode, isPreviewMode, getTimerEnabled } from 'store/settings/selectors';
import MainCircleLoader from 'components/common/MainCircleLoader/MainCircleLoader';
import { isIndexView } from 'components/shell/RouteTransition/viewChecker';
import { getTimerElapsed, getTimerStatus } from 'store/timer/selectors';
import * as courseTimerActions from 'store/timer/actions';
import { TimerStatus } from 'constants/timer';

type PrivateRouteProps = {
  timerActions: { [key: string]: any };
  component: any;
  componentNoAccess: any;
  typeAccess: string;
  exact?: any;
  path: any;
  materialType?: string;
  isCourseProgressRestoreFetched?: boolean;
  isCourseProgressRestoreFailed?: boolean;
  isCourseLaunchFailed?: boolean;
  courseAccessLimited?: boolean;
  isLoaderVisible?: boolean;
  reviewMode?: boolean;
  previewMode?: boolean;
  isTimerEnabled?: boolean;
  timerStatus?: any;
  timeElapsed?: number;
  isCourseStarted?: boolean;
  location?: { [key: string]: any };
};

const materialTypeCourseComponents = (displayComponents = false, Component?: any, props?: any) => {
  if (displayComponents) {
    return <Component {...props} />;
  }
  return <MainCircleLoader />;
};

export const PrivateRoute = ({
  component: Component,
  componentNoAccess: ComponentNoAccess,
  typeAccess,
  materialType,
  isCourseProgressRestoreFetched,
  isCourseProgressRestoreFailed,
  isCourseLaunchFailed,
  courseAccessLimited,
  isLoaderVisible,
  path,
  timerActions,
  isTimerEnabled,
  timerStatus,
  timeElapsed,
  reviewMode,
  previewMode,
  isCourseStarted,
  location,
  ...rest
}: PrivateRouteProps) => (
  <Route
    {...rest}
    render={props => {
      if (typeAccess === IS_NO_ACCESS && !isLoaderVisible) {
        return <ComponentNoAccess {...props} />;
      }
      if (typeAccess === IS_NOT_AUTHORIZED) {
        return <Redirect to={LOGIN_PATH} />;
      }
      if (path === SECTIONS_PATH || path === SECTION_PATH || path === QUESTION_PATH) {
        if (
          isTimerEnabled &&
          (timerStatus === TimerStatus.NOT_STARTED || timerStatus === TimerStatus.RESET)
        ) {
          timerActions.setTimerElapsed(timeElapsed);
          timerActions.startTimer();
        }
      }
      if (materialType === MATERIAL_TYPES.COURSE) {
        return materialTypeCourseComponents(
          courseAccessLimited ||
            reviewMode ||
            previewMode ||
            isCourseProgressRestoreFetched ||
            !isCourseLaunchFailed ||
            (isCourseProgressRestoreFailed &&
              isCourseStarted &&
              isIndexView.test(location?.pathname)),
          Component,
          props
        );
      }
      if (!isLoaderVisible) {
        return <Component {...props} />;
      }
      return <></>;
    }}
  />
);

function mapStateToProps(state: RootAppState) {
  return {
    isCourseProgressRestoreFetched: isProgressRestoreFetched(state),
    isCourseProgressRestoreFailed: isProgressRestoreFailed(state),
    isCourseLaunchFailed: isCourseLaunchFailed(state),
    courseAccessLimited: isCourseAccessLimited(state),
    isLoaderVisible: isLoaderVisibility(state),
    isTimerEnabled: getTimerEnabled(state),
    timerStatus: getTimerStatus(state),
    timeElapsed: getTimerElapsed(state),
    reviewMode: isReviewMode(state),
    previewMode: isPreviewMode(state),
    isCourseStarted: hasBeenStarted(state)
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    timerActions: bindActionCreators(courseTimerActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
