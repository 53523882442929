export * from './screenResolutions';
/* eslint-disable no-useless-escape */
export const imageResizerQueryPattern = /\?width=\d+\&height=\d+&scaleBySmallerSide=\w+/;
export const supportedBgSizes = {
  contain: 'contain',
  cover: 'cover',
  initial: 'initial'
};
export const CONTENT_TYPES = {
  JSON: 'application/json'
};

export const DEFAULT_LANGUAGE_CODE = 'en';
export const CUSTOM_LANGUAGE_CODE = 'xx';
export const NO_SELECTED_ID = 'no-selected-elements';
export const IMAGE_DEFAULT_DOMAIN = 'easygenerator.com';

export const maxCountTocNavigationElements = 8;
export const minCountTocNavigationElements = 5;

export const DEFAULT_QUESTION_POOL_ID_LENGTH = 7;
export const defaultBoxShadowColor = '#000';

export const MASTERY_SCORE = 50;

export const LTI_CALLBACK_URL_PARAMETER_NAME = 'ltiResultCallbackUrl';

export const FONT_WEIGHTS = ['300', '600'];

export const SECONDARY_FONT_KEY = 'secondary-font';

export const MEDIA_FONT_SIZES = {
  heading1: {
    MAXIMUM_VALUE: 36,
    MULTIPLIER: 0.94
  },
  heading2: {
    MAXIMUM_VALUE: 30,
    MULTIPLIER: 0.93
  },
  heading3: {
    MAXIMUM_VALUE: 26,
    MULTIPLIER: 0.91
  },
  heading4: {
    MAXIMUM_VALUE: 24,
    MULTIPLIER: 0.9
  },
  paragraph: {
    MAXIMUM_VALUE: 22,
    MULTIPLIER: 1
  },
  quote: {
    MAXIMUM_VALUE: 24,
    MULTIPLIER: 0.92
  }
};

export const TRANSITION_TIME_DURATION_MS = 300;

export enum MATERIAL_TYPES {
  RESOURCE = 'resource',
  COURSE = 'course'
}

export enum RESOURCE_TYPE {
  CHECKLIST = 'checklist',
  HOWTO = 'howTo'
}

export enum DROP_REASON {
  DROP = 'DROP',
  CANCEL = 'CANCEL'
}
export const CIRCLE_DEGREES = 360;

export const HTML_INFINITY_SYMBOL = '&#8734;';
export const SVG_XML_NAMESPACE = 'http://www.w3.org/2000/svg';

export const DEBOUNCE_SCROLL_WAIT_TIME_MS = 200;

export const RECALCULATE_LOCATION_TIME_MS = 1000;

export const EULA_PDF_LINK = '//cdn.easygenerator.com/eula/Easygenerator_Eula_for_Learners.pdf';

export const KEYS: { [key: string]: string } = {
  ENTER: 'Enter',
  SPACEBAR: ' ',
  TAB: 'Tab',
  ESCAPE: 'Escape'
};

export const EMAIL_REGEX = new RegExp(
  [
    "(?=^[a-zA-Z0-9@.!#$%&'*+\\/=?^_‘{|}~-]{6,100}$)",
    "(?=^[a-zA-Z0-9.!#$%&'*+\\/=?^_‘{|}~-]{1,64}@)",
    "^[a-zA-Z0-9!#$%&'*+\\/=?^_‘{|}~-]+",
    "(?:\\.[a-zA-Z0-9!#$%&'*+\\/=?^_‘{|}~-]+)",
    '*@((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\\.)+',
    '[a-zA-Z]{2,63}$'
  ].join('')
);

export const PASSWORD_REGEX = '\\S{7,}';

export const SKIP_TO_CONTENT_IDS = {
  LEARNING_OBJECTIVE_PAGE: 'sectionTitle',
  QUESTION_PAGE: 'questionTitleWrapper',
  RESULT_PAGE: 'resultHeader',
  ONE_PAGE_COURSE: 'stickyContainer',
  QUESTION_POOL_START_BUTTON: 'qpStart'
};

export const NAVIGATION_ITEM_ICON_NAMES = {
  OK: 'ok',
  CLOSE: 'close',
  QUESTION: 'question'
};

export const CIRCULAR_PROGRESSBAR = {
  STROKE_LINECAP: 'butt',
  TRANSITION: 'stroke-dashoffset 0.5s ease'
};
