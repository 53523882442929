import styled, { css } from 'styled-components';
import Icon from 'components/common/Icon';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import { MEDIA_TABLET } from 'constants/screenResolutions';
import { TOC } from 'constants/components';
import buttonDefaultStyle from 'components/mixins/normalize';

type ListItemsBlockProps = {
  isImageQuestion: boolean;
  isTreeOfContentExpanded: boolean;
};
export const ListItemsBlock = styled.ul<ListItemsBlockProps>`
  width: 100%;

  ${props =>
    props.isImageQuestion
      ? css`
          display: flex;
          flex-wrap: wrap;

          ${media.small`
            justify-content: space-around;
          `};
        `
      : css``};
`;

type ListItemProps = {
  selected: boolean;
  key: string;
  isImageQuestion: boolean;
  isTreeOfContentExpanded: boolean;
};
export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  min-height: 50px;
  transition: background-color 0.2s linear;
  cursor: pointer;
  position: relative;

  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.06)};
  }

  ${props =>
    props.isImageQuestion &&
    css`
          width: 260px;
          height: 260px;
          background: #f1f1f1;
          margin-top: 10px;
          margin-left: 50px;
          border: solid 2px #fff;
          border-radius: 4px;
          overflow: hidden;

          ${
            props.selected
              ? css`
                  border: solid 2px ${props.theme.colors.mainColor};
                `
              : css``
          } &:hover {
            .show-on-hover {
              opacity: 0.75;
            }

            .zoom-button {
              opacity: 0.5;
            }
          }
          ${media.xlarge`
            width: 224px;
            height: 224px;
            margin-left: 38px;
          `}

          @media (min-width: ${MEDIA_TABLET +
            (props.isTreeOfContentExpanded ? TOC.EXPANDED_WIDTH : 0)}px) {
            &:nth-child(3n + 1) {
              margin-left: 0;
            }
          }

          @media (max-width: ${MEDIA_TABLET +
            (props.isTreeOfContentExpanded ? TOC.EXPANDED_WIDTH : 0)}px) {
            width: 260px;
            height: 260px;
            margin-left: 40px;

            &:nth-child(2n + 1) {
              margin-left: 0;
            }
          }

          ${media.small`
            margin-left: 0;
            margin-bottom: 30px;
          `};
        `};
`;

export const ZoomIconBg = styled(Icon)`
  color: #271100;
`;

export const ZoomIcon = styled(Icon)`
  color: #d8d8d8;
  position: absolute;
  top: 0;
  left: 0;
`;

type ZoomIconButton = {
  className: string;
  ariaLabel: string;
  tabIndex: number;
  isShown: boolean;
};
export const ZoomIconButton = styled.button.attrs((props: ZoomIconButton) => ({
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel,
  'data-test': 'zoom-button'
}))`
  ${buttonDefaultStyle};
  background: none;
  border: 0;
  display: none;
  padding: 0;
  position: absolute;
  right: 5px;
  bottom: 5px;
  opacity: 0;
  transition: 0.3s;

  &:hover,
  &:focus {
    opacity: 0.8 !important;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  ${media.tablet`
    opacity: 0.5;
  `};

  ${(props: ZoomIconButton) =>
    props.isShown
      ? css`
          & {
            display: block;
          }
        `
      : css``};
`;
