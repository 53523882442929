import styled, { keyframes, css } from 'styled-components';
import Text from 'components/common/Text';
import Icon from 'components/common/Icon';
import { transition } from 'components/mixins/interactivity';
import { transparentize } from 'utils/color';
import { SIZES, SWITCH_BUTTON_LAYOUT } from 'constants/components';
import { defaultBoxShadowColor } from 'constants/common';

export const CheckIcon = styled(Icon)``;

export const CheckImageIcon = styled(Icon)`
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
`;

type TextElement = {
  selected: boolean;
};
export const TextElement = styled(Text)<TextElement>`
  color: ${props => props.theme.colors.textColor};
  ${transition('padding-right')};
`;

export const AnswerImage = styled.img.attrs((props: any) => ({
  alt: props.alt
}))`
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`;

type ButtonElementProps = {
  selected: boolean;
  multiple: boolean;
  size: any;
  layout: string;
  imageUrl?: string;
};
const getButtonChecklistBackgroundColor = (props: any) =>
  props.selected ? props.theme.colors.mainColor : 'transparent';
const getButtonChecklistBorder = (props: any) =>
  !props.selected ? `3px solid ${props.theme.colors.mainColor}` : `none`;
const getButtonChecklistColor = (props: any) =>
  props.selected
    ? props.theme.colors.buttonTextColor
    : transparentize(props.theme.colors.textColor, 0.2);
const getButtonBackgroundColor = (props: any) =>
  props.selected
    ? props.theme.colors.mainColor
    : transparentize(props.theme.colors.textColor, 0.06);
const getButtonBorderRadius = (props: any) => (props.multiple ? '2px' : '50%');
const getButtonCheckboxColor = (props: any) =>
  props.selected
    ? props.theme.colors.buttonTextColor
    : transparentize(props.theme.colors.mainColor, 0.3);
const getMultipleCheckIconOpacity = (props: any) => (props.selected ? '1' : '0');
const getCheckIconBackgroundColor = (props: any) => (props.selected ? '!important' : '');
const getCheckIconOpacity = (props: any) => (props.selected ? '1' : '0');
const getSelectedCss = (props: any) =>
  props.selected
    ? css`
        background-color: ${props.theme.colors.mainColor};
        opacity: 1 !important;
      `
    : css`
        opacity: 0;
        background-color: transparent !important;
      `;
const getSelectedCheckIconCss = (props: any) =>
  props.selected ? 'opacity: 1 !important' : 'opacity: 0 !important';
const getImageIconOpacity = (props: any) => (!props.selected ? 'opacity: 1' : '');
export const ButtonElement = styled.div.attrs((props: ButtonElementProps) => ({
  'data-test': 'button-element',
  'data-state': props.selected ? 'selected' : 'not-selected'
}))<ButtonElementProps>`
  flex-shrink: 0;
  align-self: self-start;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.layout === SWITCH_BUTTON_LAYOUT.WITH_TEXT ||
    props.layout === SWITCH_BUTTON_LAYOUT.IMAGE_ANSWER
      ? css`
          width: 30px;
          height: 30px;
          background-color: ${getButtonBackgroundColor(props)};
          border-radius: ${getButtonBorderRadius(props)};
          margin-right: 20px;
        `
      : ''}

  ${props =>
    props.layout === SWITCH_BUTTON_LAYOUT.CHECKLIST
      ? css`
          width: 32px;
          height: 32px;
          background-color: ${getButtonChecklistBackgroundColor(props)};
          border-radius: 8px;
          border: ${getButtonChecklistBorder(props)};
        `
      : css`
          position: absolute;
          top: 50%;
          left: 10px;
          margin-top: -15px;
        `}
  
  ${props =>
    props.size === SIZES.SMALL &&
    css`
      width: 15px;
      height: 15px;
      margin-right: 8px;

      & ${CheckIcon} {
        font-size: 5px;
      }
    `};

  ${props =>
    props.multiple
      ? css`
          & ${CheckIcon} {
            opacity: ${getMultipleCheckIconOpacity(props)};
            color: ${getButtonChecklistColor(props)};

            ${props.layout === SWITCH_BUTTON_LAYOUT.CHECKLIST &&
              `
              color:${getButtonCheckboxColor(props)};
                }
              &:hover {
                cursor: pointer;
                box-shadow: 0 0 8px 0 ${transparentize(props.theme.colors.mainColor, 0.3)}
              }
            `}
          }
        `
      : css`
          & ${CheckIcon} {
            display: none;
          }

          &:before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background-color: #fff ${getCheckIconBackgroundColor(props)};
            border-radius: 50%;
            opacity: ${getCheckIconOpacity(props)};
          }
        `};
        
  ${props =>
    props.imageUrl
      ? css`
          position: absolute;
          width: 60px;
          height: 60px;
          top: 50%;
          left: 50%;
          margin-top: -30px;
          margin-left: -30px;
          margin-right: 0;
          box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
          transition: 0.3s;

          ${getSelectedCss(props)};

          & ${CheckIcon} {
            display: block;
            transition: 0.3s;
            ${getSelectedCheckIconCss(props)};
            font-size: 14px;
          }
          & ${CheckImageIcon} {
            ${getImageIconOpacity(props)};
          }

          &:before {
            display: none;
          }
        `
      : css``};
  }
`;

const checkMarkAnimate = keyframes`
  0% {
        transform: scale(1);
  }
  50% {
        transform: scale(0.85);
  }
  100% {
        transform: rotate(1);
  }
`;

type ButtonWrapperProps = {
  tabIndex: number;
  roleValue: string;
  imageUrl?: string;
  ariaLabel?: string;
  ariaDisabled: boolean;
  selected: boolean;
  ariaChecked: boolean;
  layout: string;
};
export const ButtonWrapper = styled.div.attrs((props: ButtonWrapperProps) => ({
  tabIndex: props.tabIndex,
  role: props.roleValue,
  'aria-checked': props.selected ? true : false,
  'aria-label': props.imageUrl ? props.ariaLabel : undefined,
  'data-state': props.selected ? 'selected' : 'not-selected',
  'aria-disabled': props.ariaDisabled,
  'data-test': 'switch-button'
}))<ButtonWrapperProps>`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;

  ${props =>
    props.layout === SWITCH_BUTTON_LAYOUT.WITH_TEXT &&
    css`
      padding: 10px 10px 10px 60px;
    `}

  &:hover {
    & ${CheckIcon} {
      opacity: 1;
    }
    ${ButtonElement}:before {
      opacity: 1;
      background-color: ${props => transparentize(props.theme.colors.textColor, 0.2)};
    }
  }

  ${props =>
    props.selected && props.imageUrl
      ? css`
          ${ButtonElement} {
            animation: ${checkMarkAnimate} 0.4s linear 1 both;
          }
        `
      : css``};
  &:focus {
    .show-on-hover {
      opacity: 0.75;
    }
`;
