import styled from 'styled-components';
import { transition } from 'components/mixins/interactivity';

type IconElementProps = {
  size: number;
  opacity: any;
};
/* eslint-disable import/prefer-default-export */
export const IconElement = styled.span<IconElementProps>`
  position: relative;
  font-size: ${props => props.size}px;
  color: ${props => getColor(props)};
  opacity: ${props => props.opacity};
  ${transition('color')};
`;

type TextElementProps = {
  fontSize: number;
  theme?: { [key: string]: any };
};
export const TextElement = styled.span<TextElementProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: ${props => `${props.fontSize}px`};
  color: ${props => getColor(props)};
  ${transition('color')};
`;

function getColor(props: any) {
  if (props.getColor) {
    return props.getColor(props.theme.colors);
  }

  return props.theme.colors[props.color];
}
